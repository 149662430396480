<template>
  <div class="page flex-col">
    <div class="box_3 flex-col">
      <div class="image-text_1 flex-row justify-between">
        <img class="image_2" referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng978d3889c18f054a63e09bfd1e144aff63a3bfbaaf2abb2ab049da9748eed2a9" />
        <span class="text-group_1">{{$t('views_mycontribution_index_77')}}</span>
      </div>
      <div class="list_1 flex-col">
        <div class="list-items_1 flex-row justify-between" v-for="(item, index) in loopData0" :key="index">
          <div class="text-group_2 flex-col">
            <span class="text_3">{{$t('views_mycontribution_index_78')}}</span>
            <span class="text_4">{{$t('views_community_components_promotion_levels_16')}}</span>
          </div>
          <div class="text-group_3 flex-col">
            <span class="text_5">{{item.startTime|date}}</span>
            <span class="text_6">{{ item.amount | coin }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import i18n from '../../locales/index'

import { getClockWiseContract, getClockWiseStorageContract } from '@/utils/contract';
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(["userAdd"])
  },
  data() {
    return {
      loopData0: [
      ],
      constants: {},
    };
  },
  watch: {
    userAdd() {
      this.getPledge();
    }
  },
  mounted() {
    this.getPledge();
  },
  methods: {
    async getPledge() {
      let r = await getClockWiseStorageContract().methods.getUserPledge(this.userAdd).call({from:this.userAdd});
      console.log(r)
      this.loopData0.push(...r.reverse())
    },
  },
};
</script>
<style lang="scss" src="@/scss/common.scss" />
<style lang="scss" scoped src="./index.scss">