import i18n from './locales/index'

require("@/utils/get_url_params")
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
require("@/utils/rem")
import ElementUI from 'element-ui'
import 'element-theme-dark';
require("./globalComponents")
require("./filters")
//require("./utils/v4_router")
//require("./utils/smart_router")
Vue.config.productionTip = false
window.mainNet = true;// 是否主链
Vue.use(ElementUI)
setTimeout(() => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}, 300);
