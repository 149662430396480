import axios from "axios";
import jsonBigInt from "json-bigint";

const instance = axios.create({
    baseURL:"https://clockwisechain.cc/",
    transformRequest:[(data,header)=>{
        header["Content-Type"] = 'application/json'
        return jsonBigInt.stringify(data)
    }]
})
function getSignature(refer,referLevel,amount,path,minAmountOut,level,sender) {
    return instance.get(window.mainNet?"/api/mainnet/getSignature":"/api/testnet/getSignature",{params:{refer,referLevel,amount,path,minAmountOut,level,sender}})
}
export {
    getSignature
};