import i18n from '../locales/index'
export default {
    "abi":[
        {
            "inputs": [],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "target",
                    "type": "address"
                }
            ],
            "name": "AddressEmptyCode",
            "type": "error"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "implementation",
                    "type": "address"
                }
            ],
            "name": "ERC1967InvalidImplementation",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "ERC1967NonPayable",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "FailedInnerCall",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "InvalidInitialization",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "NotInitializing",
            "type": "error"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                }
            ],
            "name": "OwnableInvalidOwner",
            "type": "error"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "account",
                    "type": "address"
                }
            ],
            "name": "OwnableUnauthorizedAccount",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "UUPSUnauthorizedCallContext",
            "type": "error"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes32",
                    "name": "slot",
                    "type": "bytes32"
                }
            ],
            "name": "UUPSUnsupportedProxiableUUID",
            "type": "error"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "uint64",
                    "name": "version",
                    "type": "uint64"
                }
            ],
            "name": "Initialized",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "previousOwner",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "newOwner",
                    "type": "address"
                }
            ],
            "name": "OwnershipTransferred",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "implementation",
                    "type": "address"
                }
            ],
            "name": "Upgraded",
            "type": "event"
        },
        {
            "inputs": [],
            "name": "UPGRADE_INTERFACE_VERSION",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "_ROUTER",
            "outputs": [
                {
                    "internalType": "contract ISwapRouter",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "account",
                    "type": "address"
                }
            ],
            "name": "checkPawns",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "account",
                    "type": "address"
                }
            ],
            "name": "checkRankings",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "clearPawn",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                }
            ],
            "name": "clearPendingDynamic",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                }
            ],
            "name": "clearProfitRewardTotal",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "clearReferAddr",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "clearState",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "clearStorage",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "refer_",
                    "type": "address"
                }
            ],
            "name": "closePledge",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.ConfigType",
                    "name": "",
                    "type": "uint8"
                }
            ],
            "name": "config",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "dayTimes",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.RecordType",
                    "name": "_type",
                    "type": "uint8"
                }
            ],
            "name": "getHistory",
            "outputs": [
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "score",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "receivedAmount",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.RankData[]",
                    "name": "",
                    "type": "tuple[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.RecordType",
                    "name": "type_",
                    "type": "uint8"
                }
            ],
            "name": "getHistoryRankLen",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "getPawns",
            "outputs": [
                {
                    "internalType": "address[]",
                    "name": "",
                    "type": "address[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.RecordType",
                    "name": "_tp",
                    "type": "uint8"
                }
            ],
            "name": "getRanking",
            "outputs": [
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "score",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "receivedAmount",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.RankData[]",
                    "name": "",
                    "type": "tuple[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "getRankingRateLen",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "getRankingsData",
            "outputs": [
                {
                    "internalType": "address[]",
                    "name": "",
                    "type": "address[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_user",
                    "type": "address"
                }
            ],
            "name": "getReceiveRecord",
            "outputs": [
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "times",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amount",
                            "type": "uint256"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.Receive[]",
                    "name": "",
                    "type": "tuple[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "getScores",
            "outputs": [
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "score",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "receivedAmount",
                            "type": "uint256"
                        },
                        {
                            "internalType": "bool",
                            "name": "isExist",
                            "type": "bool"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.Player[]",
                    "name": "",
                    "type": "tuple[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "getTodayUser",
            "outputs": [
                {
                    "internalType": "address[]",
                    "name": "",
                    "type": "address[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_user",
                    "type": "address"
                }
            ],
            "name": "getUserMemberDetails",
            "outputs": [
                {
                    "components": [
                        {
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "level",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "devote",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "proffer",
                            "type": "uint256"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.RecommandDetail[]",
                    "name": "",
                    "type": "tuple[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                }
            ],
            "name": "getUserPledge",
            "outputs": [
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "amount",
                            "type": "uint256"
                        },
                        {
                            "components": [
                                {
                                    "internalType": "address",
                                    "name": "contracts",
                                    "type": "address"
                                },
                                {
                                    "internalType": "uint256[]",
                                    "name": "amt",
                                    "type": "uint256[]"
                                }
                            ],
                            "internalType": "struct ClockWiseStorage.TokenAsset",
                            "name": "amtInfo",
                            "type": "tuple"
                        },
                        {
                            "internalType": "uint256",
                            "name": "handsel",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "startTime",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "lastTime",
                            "type": "uint256"
                        },
                        {
                            "internalType": "bool",
                            "name": "isLock",
                            "type": "bool"
                        },
                        {
                            "internalType": "bool",
                            "name": "isClose",
                            "type": "bool"
                        },
                        {
                            "internalType": "bool",
                            "name": "isEnd",
                            "type": "bool"
                        },
                        {
                            "internalType": "bool",
                            "name": "isExactStatic",
                            "type": "bool"
                        },
                        {
                            "internalType": "uint256",
                            "name": "exactTime",
                            "type": "uint256"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.PledgeInfo[]",
                    "name": "",
                    "type": "tuple[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "graveAddr",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_player",
                    "type": "address"
                }
            ],
            "name": "hasScore",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.RecordType",
                    "name": "",
                    "type": "uint8"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "historyRank",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "score",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "receivedAmount",
                    "type": "uint256"
                },
                {
                    "internalType": "address",
                    "name": "account",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "amt_",
                    "type": "uint256"
                }
            ],
            "name": "increaseLootteryPool",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "amt_",
                    "type": "uint256"
                }
            ],
            "name": "increasePawn",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "increasePawnPool",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "_amt",
                    "type": "uint256"
                }
            ],
            "name": "increasePlayersReceivedAmount",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "refer_",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amt_",
                    "type": "uint256"
                }
            ],
            "name": "increasePlayersScore",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to_",
                    "type": "address"
                }
            ],
            "name": "increaseRankings",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "increaseReferAddr",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "refer",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "increaseRewardInvite",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                }
            ],
            "name": "increaseTeamUser",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "parent",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "increaseTeamsAmount",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "increaseUserAmt",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "increaseUserDynamic",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                }
            ],
            "name": "increaseUserPledgeIndex",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "increaseUserStatic",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "increaseUserTotal",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "initialize",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "lotteryPool",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "manager",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "owner",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "pawn",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "pawnPool",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "pawns",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "perkAddr",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "pickUpTokenTimes",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "players",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "score",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "receivedAmount",
                    "type": "uint256"
                },
                {
                    "internalType": "bool",
                    "name": "isExist",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "preToken",
            "outputs": [
                {
                    "internalType": "contract IERC20",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "profitReward",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "direct",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "interPush",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "lottery",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "bouns",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "star",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "dividends",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "flatLevel",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "total",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "promotion",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "percentage",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "achieve",
                    "type": "uint256"
                },
                {
                    "internalType": "bool",
                    "name": "isSizeZone",
                    "type": "bool"
                },
                {
                    "internalType": "uint256",
                    "name": "extraIncome",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "promotionMax",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "proxiableUUID",
            "outputs": [
                {
                    "internalType": "bytes32",
                    "name": "",
                    "type": "bytes32"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "addr_",
                    "type": "address"
                }
            ],
            "name": "pushPawns",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "refer",
                    "type": "address"
                },
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "direct",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "interPush",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "lottery",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "bouns",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "star",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "dividends",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "flatLevel",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "total",
                            "type": "uint256"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.ProfitRewardDetails",
                    "name": "info",
                    "type": "tuple"
                }
            ],
            "name": "pushProfitReward",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                },
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "times",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amount",
                            "type": "uint256"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.Receive",
                    "name": "info",
                    "type": "tuple"
                }
            ],
            "name": "pushReceive",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.RecordType",
                    "name": "type_",
                    "type": "uint8"
                },
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "score",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "receivedAmount",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.RankData",
                    "name": "info",
                    "type": "tuple"
                }
            ],
            "name": "pushTodayRank",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                }
            ],
            "name": "pushTodayUser",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "refer",
                    "type": "address"
                },
                {
                    "components": [
                        {
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "level",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "devote",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "proffer",
                            "type": "uint256"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.RecommandDetail",
                    "name": "info",
                    "type": "tuple"
                }
            ],
            "name": "pushUserMember",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                },
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "amount",
                            "type": "uint256"
                        },
                        {
                            "components": [
                                {
                                    "internalType": "address",
                                    "name": "contracts",
                                    "type": "address"
                                },
                                {
                                    "internalType": "uint256[]",
                                    "name": "amt",
                                    "type": "uint256[]"
                                }
                            ],
                            "internalType": "struct ClockWiseStorage.TokenAsset",
                            "name": "amtInfo",
                            "type": "tuple"
                        },
                        {
                            "internalType": "uint256",
                            "name": "handsel",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "startTime",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "lastTime",
                            "type": "uint256"
                        },
                        {
                            "internalType": "bool",
                            "name": "isLock",
                            "type": "bool"
                        },
                        {
                            "internalType": "bool",
                            "name": "isClose",
                            "type": "bool"
                        },
                        {
                            "internalType": "bool",
                            "name": "isEnd",
                            "type": "bool"
                        },
                        {
                            "internalType": "bool",
                            "name": "isExactStatic",
                            "type": "bool"
                        },
                        {
                            "internalType": "uint256",
                            "name": "exactTime",
                            "type": "uint256"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.PledgeInfo",
                    "name": "info_",
                    "type": "tuple"
                }
            ],
            "name": "pushUserPledge",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "rankingRate",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "rankings",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "receiveRecord",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "times",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "referAddr",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "referTimes",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "registerUser",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "isExists",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.RecordType",
                    "name": "type_",
                    "type": "uint8"
                }
            ],
            "name": "removeHistoryRank",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.RecordType",
                    "name": "type_",
                    "type": "uint8"
                },
                {
                    "internalType": "uint256",
                    "name": "_idx",
                    "type": "uint256"
                }
            ],
            "name": "removeHistoryRankByIdx",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "removePawns",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to_",
                    "type": "address"
                }
            ],
            "name": "removePlayers",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "removeRankings",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.RecordType",
                    "name": "type_",
                    "type": "uint8"
                }
            ],
            "name": "removeTodayRank",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "removeTodayUser",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "renounceOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bool",
                    "name": "state_",
                    "type": "bool"
                }
            ],
            "name": "setClearState",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "wise_",
                    "type": "address"
                },
                {
                    "internalType": "bool",
                    "name": "state_",
                    "type": "bool"
                }
            ],
            "name": "setClockWise",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.ConfigType",
                    "name": "_type",
                    "type": "uint8"
                },
                {
                    "internalType": "uint256",
                    "name": "_value",
                    "type": "uint256"
                }
            ],
            "name": "setConfig",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.ConfigType256",
                    "name": "_type",
                    "type": "uint8"
                },
                {
                    "internalType": "uint256",
                    "name": "_value",
                    "type": "uint256"
                }
            ],
            "name": "setConfig256",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.RecordType",
                    "name": "type_",
                    "type": "uint8"
                },
                {
                    "internalType": "uint256",
                    "name": "i_",
                    "type": "uint256"
                },
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "score",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "receivedAmount",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.RankData",
                    "name": "info",
                    "type": "tuple"
                }
            ],
            "name": "setHistoryRank",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "refer_",
                    "type": "address"
                },
                {
                    "internalType": "bool",
                    "name": "isExists_",
                    "type": "bool"
                }
            ],
            "name": "setPlayersExist",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "percentage",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "achieve",
                            "type": "uint256"
                        },
                        {
                            "internalType": "bool",
                            "name": "isSizeZone",
                            "type": "bool"
                        },
                        {
                            "internalType": "uint256",
                            "name": "extraIncome",
                            "type": "uint256"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.level[]",
                    "name": "_promo",
                    "type": "tuple[]"
                }
            ],
            "name": "setPromotion",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256[]",
                    "name": "_rankRate",
                    "type": "uint256[]"
                }
            ],
            "name": "setRankingRate",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "bool",
                    "name": "state",
                    "type": "bool"
                }
            ],
            "name": "setRegisterUserIsExists",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.Token",
                    "name": "_token",
                    "type": "uint8"
                },
                {
                    "internalType": "address",
                    "name": "_addr",
                    "type": "address"
                }
            ],
            "name": "setTokenAddr",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                }
            ],
            "name": "setUserAddr",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "setUserAmt",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "refer",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                }
            ],
            "name": "setUserInvites",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "bool",
                    "name": "state_",
                    "type": "bool"
                }
            ],
            "name": "setUserIsExist",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "parent",
                    "type": "address"
                }
            ],
            "name": "setUserRefer",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "total_",
                    "type": "uint256"
                }
            ],
            "name": "setUserTotal",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.WalletAddr",
                    "name": "_type",
                    "type": "uint8"
                },
                {
                    "internalType": "address",
                    "name": "_addr",
                    "type": "address"
                }
            ],
            "name": "setWalletAddr",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "stocksAddr",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "subLotteryPool",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "subPawnPool",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "subPendingDynamic",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "subProfitRewardTotal",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "subUserAmt",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "system",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "teams",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "level",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "invites",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "teamUser",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "teamAmount",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "rewardStatic",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "rewardInvite",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "enum ClockWiseStorage.RecordType",
                    "name": "",
                    "type": "uint8"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "todayRank",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "score",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "receivedAmount",
                    "type": "uint256"
                },
                {
                    "internalType": "address",
                    "name": "account",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "todayUser",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "newOwner",
                    "type": "address"
                }
            ],
            "name": "transferOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_msg",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "updateBounsAmt",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "index_",
                    "type": "uint256"
                },
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                },
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "amount",
                            "type": "uint256"
                        },
                        {
                            "components": [
                                {
                                    "internalType": "address",
                                    "name": "contracts",
                                    "type": "address"
                                },
                                {
                                    "internalType": "uint256[]",
                                    "name": "amt",
                                    "type": "uint256[]"
                                }
                            ],
                            "internalType": "struct ClockWiseStorage.TokenAsset",
                            "name": "amtInfo",
                            "type": "tuple"
                        },
                        {
                            "internalType": "uint256",
                            "name": "handsel",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "startTime",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "lastTime",
                            "type": "uint256"
                        },
                        {
                            "internalType": "bool",
                            "name": "isLock",
                            "type": "bool"
                        },
                        {
                            "internalType": "bool",
                            "name": "isClose",
                            "type": "bool"
                        },
                        {
                            "internalType": "bool",
                            "name": "isEnd",
                            "type": "bool"
                        },
                        {
                            "internalType": "bool",
                            "name": "isExactStatic",
                            "type": "bool"
                        },
                        {
                            "internalType": "uint256",
                            "name": "exactTime",
                            "type": "uint256"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.PledgeInfo",
                    "name": "info",
                    "type": "tuple"
                }
            ],
            "name": "updatePledge",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "level_",
                    "type": "uint256"
                }
            ],
            "name": "updateTeamsLevel",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "refer",
                    "type": "address"
                }
            ],
            "name": "updateUserInviteTotals",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "components": [
                        {
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "level",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "devote",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "proffer",
                            "type": "uint256"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.RecommandDetail[]",
                    "name": "info",
                    "type": "tuple[]"
                }
            ],
            "name": "updateUserMembers",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "level_",
                    "type": "uint256"
                }
            ],
            "name": "updateUsersLevel",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "msg_",
                    "type": "address"
                }
            ],
            "name": "updateWithdrawLast",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "newImplementation",
                    "type": "address"
                },
                {
                    "internalType": "bytes",
                    "name": "data",
                    "type": "bytes"
                }
            ],
            "name": "upgradeToAndCall",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "usdtToken",
            "outputs": [
                {
                    "internalType": "contract IERC20",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "userAddr",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "userInviteTotals",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "userInvites",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "userMember",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "account",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "level",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "devote",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "proffer",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "userPledge",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                },
                {
                    "components": [
                        {
                            "internalType": "address",
                            "name": "contracts",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256[]",
                            "name": "amt",
                            "type": "uint256[]"
                        }
                    ],
                    "internalType": "struct ClockWiseStorage.TokenAsset",
                    "name": "amtInfo",
                    "type": "tuple"
                },
                {
                    "internalType": "uint256",
                    "name": "handsel",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "startTime",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "lastTime",
                    "type": "uint256"
                },
                {
                    "internalType": "bool",
                    "name": "isLock",
                    "type": "bool"
                },
                {
                    "internalType": "bool",
                    "name": "isClose",
                    "type": "bool"
                },
                {
                    "internalType": "bool",
                    "name": "isEnd",
                    "type": "bool"
                },
                {
                    "internalType": "bool",
                    "name": "isExactStatic",
                    "type": "bool"
                },
                {
                    "internalType": "uint256",
                    "name": "exactTime",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "userPledgeIndex",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "userTotal",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "users",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "isExist",
                    "type": "bool"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "pendingStatic",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "pendingDynamic",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "pendingInvite",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "withdrawLast",
                    "type": "uint256"
                },
                {
                    "internalType": "uint8",
                    "name": "level",
                    "type": "uint8"
                },
                {
                    "internalType": "address",
                    "name": "refer",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "wises",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }
    ],
}