<template>
    <div>
        <Account></Account>
    </div>
</template>
<script>
import i18n from '../../locales/index'

import Account from './components/account.vue';
export default {
    components:{
        Account
    },
    data(){
        return {

        }
    },
    methods:{

    }
}
</script>