import i18n from '../locales/index'
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/index.vue'
import community from '../views/community/index.vue'
import myassets from '../views/myassets/index.vue'
import mycontribution from '@/views/mycontribution/index.vue'
import rankingyesterday from '@/views/rankingyesterday/index.vue'
import receiverecord from '@/views/receiverecord/index.vue'
import examples from '@/views/examples/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/examples',
    name: 'examples',
    component: examples
  },
  {
    path: '/community',
    name: 'community',
    component: community
  },
  {
    path: '/myassets',
    name: 'myassets',
    component: myassets
  },
  {
    path: '/mycontribution',
    name: 'mycontribution',
    component: mycontribution
  },
  {
    path: '/rankingyesterday',
    name: 'rankingyesterday',
    component: rankingyesterday
  },
  {
    path: '/receiverecord',
    name: 'receiverecord',
    component: receiverecord
  }
]

const router = new VueRouter({
  routes
})
router.afterEach((to,from)=>{
  setTimeout(() => {
    window.scrollTo(0,0)
  }, 0);
})
export default router
