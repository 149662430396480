<template>
  <div>
  <div class="pageCommunity flex-col">
    <div class="group_2 flex-col">
      <div class="group_3 flex-row">
        <div class="image-text_1 flex-row justify-between">
          <img class="image_2" referrerpolicy="no-referrer"
            src="@/assets/SketchPng978d3889c18f054a63e09bfd1e144aff63a3bfbaaf2abb2ab049da9748eed2a9.png" />
          <span class="text-group_1">{{$t('components_menu_9')}}</span>
        </div>
      </div>
    </div>
    <div class="group_4 flex-col">
      <div class="group_5 flex-row justify-between">
        <div class="image-text_2 flex-row justify-between">
          <img class="icon_3" referrerpolicy="no-referrer"
            src="@/assets/SketchPnge7eac5d21f98e46acaf233608d6938ade513aecb654f7fd2ff88aee0ee625d26.png" />
            &nbsp;
          <span class="text-group_2">{{$t('views_community_index_19')}}</span>
        </div>
        <div class="image-text_3 flex-row justify-between" v-show='false'>
          <span class="text-group_3">{{$t('views_community_components_promotion_levels_14')}}</span>
          <img class="icon_4" referrerpolicy="no-referrer"
            src="@/assets/SketchPng1f9a53fffe18ed581e3b21c787b8ac872612a8aeded8fc994fe70f6cd5a08f37.png" />
        </div>
      </div>
      <div class="text-wrapper_2 flex-col">
        <span class="text_3">{{ inviteLink }}</span>
      </div>
      <button class="button_1 flex-col" @click="copyInviteLink">
        <span class="text_4">{{$t('views_community_index_20')}}</span>
      </button>
    </div>
    <div class="group_6 flex-col">
      <div class="image-text_4 flex-row justify-between">
        <img class="image_3" referrerpolicy="no-referrer"
          src="@/assets/SketchPng978d3889c18f054a63e09bfd1e144aff63a3bfbaaf2abb2ab049da9748eed2a9.png" />
        <span class="text-group_4">{{$t('components_menu_9')}}</span>
      </div>
      <div class="box_2 flex-row">
        <div class="text-group_5 flex-col">
          <span class="text_5">{{$t('views_community_index_21')}}</span> <span class="text_6">{{ userInfo.amount|coin}}</span>
        </div>
        <div class="block_1 flex-col"></div>
        <div class="text-group_6 flex-col">
          <span class="text_7">{{$t('views_community_index_22')}}</span> <span class="text_8">{{ userInviteTotals }}</span>
        </div>
        <div class="block_2 flex-col"></div>
        <div class="text-group_7 flex-col">
          <span class="text_9">{{$t('views_community_index_23')}}</span>
          <span class="text_10">S{{ userInfo.level }}</span>
        </div>
      </div>


      <div class="box_2 flex-row">
        <!-- <div class="block_1 flex-col"></div>
        <div class="text-group_6 flex-col">
          <span class="text_7">加权分红</span> <span class="text_8">{{ profitRewardDetails.dividends|coin }}</span>
        </div> -->
        
        <div class="text-group_5 flex-col">
          <span class="text_5">{{$t('views_community_index_24')}}</span>
          <span class="text_6" style="max-width:initial">{{teamsInfo.teamAmount|coin}}</span>
        </div>
        
        <div class="block_2 flex-col"></div>
        
        <div class="text-group_7 flex-col">
          <span class="text_9">{{$t('views_community_index_25')}}</span>
          <span class="text_10" v-if="xiaoQuYeJi==='0'">{{xiaoQuYeJi}}</span>
          <span class="text_10" v-if="xiaoQuYeJi!=='0'">{{xiaoQuYeJi|coin}}</span>
          <!-- <span class="text_10" v-if="userInviteTotals<2n">0</span>
          <span class="text_10" style="max-width:initial" v-if="Object.keys(teamsInfo).length>0 && userInviteTotals>=2n">{{ (teamsInfo.teamAmount - maxProffer)|coin}}</span> -->
         </div>

        <!-- <div class="text-group_8 flex-col">
          <span class="text_11">用户星级</span>
          <span class="text_12">{{ userInfo.level }}</span>
        </div>
        <div class="section_2 flex-col"></div>
        <div class="text-group_9 flex-col">
          <span class="text_13">加权分红</span>
          <span class="text_14">{{profitRewardDetails.dividends|coin}}U</span>
        </div>
         -->
      </div>

      <div class="image-text_5 flex-row justify-between">
        <img class="image_4" referrerpolicy="no-referrer"
          src="@/assets/c7201d20761143799d6df980e96fbdd9_mergeImage.png" />&nbsp;
        <span class="text-group_10">{{$t('views_community_index_26')}}</span>
      </div>
      <div class="list_1 flex-col">
        <div class="list-items_1 flex-row justify-between" v-for="(item, index) in userMemberDetails" :key="index">
          <div class="text-group_11 flex-col">
            <span class="text_15">{{$t('views_community_index_27')}}</span>
            <span class="text_15">{{$t('views_community_index_23')}}</span>
            <span class="text_16">{{$t('views_community_index_21')}}</span>
            <span class="text_17">{{$t('views_community_index_28')}}</span>
          </div>
          <div class="text-group_12 flex-col">
            <span class="text_18">{{item.account|shortAddr}}</span>
            <span class="text_18">S{{ item.level }}</span>
            <span class="text_19">{{ item.devote|coin }}</span>
            <span class="text_20">{{ item.proffer|coin }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
    <appFooter></appFooter>
  </div>
</template>
<script>
import i18n from '../../locales/index'

import clock_wise from "@/config/clock_wise";
import test_coin from "@/config/test_coin";
import { mapState } from "vuex";
import Web3 from "web3";
import { getClockWiseContract, getClockWiseStorageContract, getCoinContract } from "@/utils/contract";
import { copyToClipboard } from "@/utils";
import web3 from "@/utils/myweb3";
export default {
  computed: {
    ...mapState(['userAdd']),
    inviteLink() {
      return `${window.location.origin}/#/?refer=${this.userAdd}`;
    },
    maxProffer(){
      let max = 0n;
      this.userMemberDetails.forEach(e=>{
        if(e.proffer + e.devote>max){
          max = e.proffer + e.devote;
        }
      })
      console.log("max",max)
      return max;
    },
  },
  watch: {
    userAdd() {
      this.initData();
    },
  },
  data() {
    return {
      userInfo: {},
      teamsInfo: {},
      userInviteTotals: null,
      profitRewardDetails :{},
      userMemberDetails:[],
      xiaoQuYeJi:"0",
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      if (!this.userAdd) return;
      console.log('initData')
      this.getUserInfo();
      this.getUserInviteTotals();
      this.profitReward();
    //  this.getUserMemberDetails();
    },
    onClick_1() {
      alert(1);
    },
    copyInviteLink() {
      copyToClipboard(this.inviteLink)
      this.$message.success(i18n.t('views_community_index_29'));
    },
    profitReward() {
      getClockWiseStorageContract().methods.profitReward(this.userAdd).call({ from: this.userAdd }).then((r) => {
        this.profitRewardDetails = r;
        console.log(r)
      })
    },
    getUserInfo() {
      getClockWiseStorageContract().methods.users(this.userAdd).call({ from: this.userAdd }).then((r) => {
        this.userInfo = r;
        console.log('this.userInfo',this.userInfo)
      })
    },
    getTeamsInfo() {
      getClockWiseStorageContract().methods.teams(this.userAdd).call({ from: this.userAdd }).then((r) => {
        this.teamsInfo = r;
        console.log('this.teamsInfo',r)
      })
    },
    //查询直推数量
    getUserInviteTotals() {
      getClockWiseStorageContract().methods.userInviteTotals(this.userAdd).call({ from: this.userAdd }).then((r) => {
        this.userInviteTotals = r;
        console.log('this.userInviteTotals',this.userInviteTotals)
        this.getXiaoQuYeJi()
      })
    },
    // 获取小区业绩
    async getXiaoQuYeJi(){
      if(this.userInviteTotals===0n){
        this.getTeamsInfo();
        return
      }
      let total = new Number(this.userInviteTotals).valueOf();
      let finished = 0;
      let rec ={};
      let maxBigInt = function(arr) {
        if(arr.length==0)return null;
        let max = arr[0].value;
        let maxObj=arr[0];
        arr.forEach(e=>{
          if(e.value>max){
            max=e.value;
            maxObj=e;
          }
        })
        return maxObj;
      }
      console.log("maxBigInt",maxBigInt([{value:1,},{value:11,},{value:33}]))
      let calcXiaoQuYeJi = ()=>{
        const arr = Object.keys(rec).map(key => ({ key, value: rec[key] }));
        let maxBigIntObj=maxBigInt(arr)
        if(maxBigIntObj){
          arr.splice(arr.indexOf(maxBigIntObj),1)
        }
        let totalValue = 0n;
        arr.forEach(e=>{
          totalValue+=e.value;
        })
        this.xiaoQuYeJi=totalValue;
        this.getTeamsInfo();
      }
      for(let i = 0;i<total;i++){
        getClockWiseStorageContract().methods.userInvites(this.userAdd, i).call().then(addr=>{
          let teamsInfo,userInfo,userMemberDetail={"account":addr,"proffer":0n};
          this.userMemberDetails.push(userMemberDetail)
          getClockWiseStorageContract().methods.teams(addr).call({ from: this.userAdd }).then((r) => {
            teamsInfo = r;
            console.log(addr,'teamsInfo',teamsInfo)
            finished++;
            if(userInfo){
              rec[addr]=teamsInfo.teamAmount+userInfo.amount;
              console.log('finished',finished)
              console.log('total',total)
              console.log('rec',rec)
              if(finished%2==0 && total*2==finished){
                calcXiaoQuYeJi()
              }
              this.$set(userMemberDetail,"level",userInfo.level)
              this.$set(userMemberDetail,"devote",userInfo.amount)
              this.$set(userMemberDetail,"proffer",teamsInfo.teamAmount)
            }
          })
          getClockWiseStorageContract().methods.users(addr).call({ from: this.userAdd }).then((r) => {
            userInfo = r;
            console.log(addr,'userInfo',userInfo)
            finished++;
            if(teamsInfo){
              rec[addr]=teamsInfo.teamAmount+userInfo.amount;
              console.log('finished',finished)
              console.log('total',total)
              console.log('rec',rec)
              this.$set(userMemberDetail,"level",userInfo.level)
              this.$set(userMemberDetail,"devote",userInfo.amount)
              this.$set(userMemberDetail,"proffer",teamsInfo.teamAmount)
              if(finished%2==0 && total*2==finished){
                calcXiaoQuYeJi()
              }
            }
          })
        });
      }

    },
    //查询直推数量
    getUserMemberDetails() {
      getClockWiseStorageContract().methods.getUserMemberDetails(this.userAdd).call({ from: this.userAdd }).then((r) => {
        this.userMemberDetails = r;
        console.log('this.userMemberDetails',this.userMemberDetails)
      })
    },
    
  },
};
</script>
<style lang="scss" src="../../scss/common.scss" />
<style lang="scss" src="./index.scss" />