<template>
  <div>
    <div class="group_1 flex-col">
      <div class="nav-bar_1 flex-row">
        <img class="image_1" referrerpolicy="no-referrer" @click="toHome"
          src="@/assets/SketchPngfd2fb6150cefcec33e3ecb62474397bb1e95870294a6c38196c3ebb42a2caf99.png" />
        <div class="text-wrapper_1 flex-col">
          <span class="text_2" v-if="userAdd">{{userAdd|shortAddr}}</span>
          <span class="text_2" v-else  @click="initByWalletConnect">{{$t('components_header_1')}}</span>
        </div>
        <div class="box_1 flex-col">
          <div class="image-wrapper_1 flex-row justify-between">
            <img class="icon_1" @click="showMenu = !showMenu" referrerpolicy="no-referrer"
              src="@/assets/SketchPng86e67bd0839700623a52521106c52bbe387cd0ce32ec69e9e0e2d1e64a6c7320.png" />
              <el-dropdown>
                <span class="el-dropdown-link" style='height: 1rem;'>
                  <img class="icon_2" referrerpolicy="no-referrer"
                            src="@/assets/SketchPng61391f73fcc572ae971d560b26b2b2103de39bd50cdb6aeb979f9d4ee20951de.png" />  </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="changeLanguage('en')">English</el-dropdown-item>
                  <el-dropdown-item @click.native="changeLanguage('tw')">繁體中文</el-dropdown-item>
                  <el-dropdown-item @click.native="changeLanguage('er')">Русский</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <web3-modal-vue
          ref="web3modal"
          :theme="theme"
          :provider-options="providerOptions"
          :cache-provider="true"
    />
    <appMenu @close="showMenu = false;" :class="{ 'showMenu': showMenu, 'hideMenu': !showMenu }"></appMenu>
  </div>
</template>
<script>
import i18n from '../locales/index'

import { waitUtil } from "@/utils";
import { getClockWiseContract, getClockWiseStorageContract } from "@/utils/contract";
import web3 from "@/utils/myweb3";
import { mapState } from "vuex";
import Web3 from "web3";

import Web3ModalVue from 'web3modal-vue2'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { ethers } from "ethers";
import encodeMixedRoute from '@/utils/v4_router';
import configList from '@/utils/configList';

export default {
  name: "header",
  components:{Web3ModalVue},
  computed:{
    ...mapState(["userAdd"])
  },
  data() {
    return {
      showMenu: false,
      theme: 'dark',
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: '9a79dc0c655d4d70a10afbb9274219c1',
          },
        },
      },
      number: 0,
      balance: 0,
      chainId:0x38,//test 0x61 prod 0x38
      chainConfigDev:[{
                            chainId: '0x61',
                            chainName: 'BNB Smart Chain Testnet',
                            nativeCurrency: {
                                name: 'BNB',
                                symbol: 'BNB',
                                decimals: 18
                            },
                            rpcUrls: [
                              'https://data-seed-prebsc-1-s2.bnbchain.org:8545',
                              'https://data-seed-prebsc-1-s3.bnbchain.org:8545',
                              'https://data-seed-prebsc-2-s2.bnbchain.org:8545',
                              'https://data-seed-prebsc-2-s3.bnbchain.org:8545',
                            ],
                            blockExplorerUrls: ['https://testnet.bscscan.com']
                    }],
      chainConfig:[{
                            chainId: '0x38',
                            chainName: 'BNB Chain',
                            nativeCurrency: {
                                name: 'BNB',
                                symbol: 'BNB',
                                decimals: 18
                            },
                            rpcUrls: [
                              'https://bsc-dataseed4.ninicoin.io',
                            ],
                            blockExplorerUrls: ['https://bscscan.com']
                    }],
    }
  },
  async mounted() {
    // var timer=setInterval(() => {
    //     if(window.ethereum||window.web3){
    //       this.init()
    //       clearInterval(timer)
    //     }
    // }, 10);
    setTimeout(() => {
      this.initByWalletConnect()
    }, 0);
  },
  methods: {
    changeLanguage(lang) {
      console.log("changeLanguage",lang)
      this.$i18n.locale = lang; // 切换语言
    },
    async initByWalletConnect(){
      //this.$refs.web3modal.clearCachedProvider()
      setTimeout(async() => {
        window.myprovider  =  await  this.$refs.web3modal.connect()
        console.log("window.myprovider.isConnected()",window.myprovider.isConnected())
          if(!window.myprovider.isConnected()){
            window.myprovider = window.ethereum || window.web3&&window.web3.currentProvider
          }
          if(window.myprovider){
            this.init(window.myprovider)
          }else{
            alert(i18n.t('components_header_2'))
            setTimeout(() => {
              window.reload()
            }, 0);
          }
      }, 200);
    },
    async init(web3Provider){
      console.log("mounted", "network check")
      try {
        // 请求用户授权
        const chainId = await web3Provider // Or window.ethereum if you don't support EIP-6963.
          .request({ method: "eth_chainId" });
          console.log('chainId',chainId)
        if (parseInt(chainId) != this.chainId) {
          try {
            await web3Provider // Or window.ethereum if you don't support EIP-6963.
              .request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: `0x38` }],
              });
            console.log("切换网络")
          } catch (switchError) {
            console.log('switchError',switchError)
            // This error code indicates that the chain has not been added to MetaMask.
            console.error(switchError, i18n.t('components_header_3'))
            if (switchError.code === 4902) {
              try {
                await web3Provider // Or window.ethereum if you don't support EIP-6963.
                  .request({
                    method: "wallet_addEthereumChain",
                    params: [{
                            chainId: '0x38',
                            chainName: 'BNB Smart Chain',
                            nativeCurrency: {
                                name: 'BNB',
                                symbol: 'BNB',
                                decimals: 18
                            },
                            rpcUrls: [
                              'https://bsc-dataseed.binance.org',
                            ],
                            blockExplorerUrls: ['https://bscscan.com']
                    }],
                  });
                  console.log('添加网络ok')
              } catch (addError) {
                // Handle "add" error.
                console.error('addError',addError)
                window.location.reload();
              }
            }else{
              window.location.reload();
              return
            }
            // Handle other "switch" errors.
          }
        }
        web3Provider // Or window.ethereum if you don't support EIP-6963.
          .on("chainChanged", this.handleChainChanged);
        web3Provider // Or window.ethereum if you don't support EIP-6963.
          .on("accountsChanged", this.handleAccountsChanged);
          let permissions=[]
        try {
           permissions = await web3Provider.request({
            "method": "wallet_getPermissions",
            "params": []
          });
        } catch (error) {
          console.error('wallet_getPermissions',error)
        }
        console.log("请求账户权限", permissions)
        this.connect()
      } catch (error) {
        // 用户不授权时
        console.error("User denied account access", error);
      }
    },
    toHome(){
      if(this.$route.path=="/")return;
      this.$router.push({path:'/'})
    },
    async handleAccountsChanged(ev){
      console.log('handleAccountsChanged',ev)
      if(!ev[0])return;
      this.$store.dispatch('updateUserAdd', ev[0])
    },
    async handleChainChanged(chainId) {
      console.log('网络变化，刷新网页');
      try {
        const chainId = await window.myprovider
          .request({ method: "eth_chainId" });
          console.log('chainId',chainId)
        if (parseInt(chainId) != this.chainId) {
          window.location.reload();
        }
      } catch (error) {
        window.location.reload();
      }
    },
    async connect() {
      if (this.userAdd) return
      let loading = this.$loading({text:i18n.t('components_header_4'),background:"#ffffff44"})
      try {
        window.Web3=Web3;
        let web3 = new Web3(window.myprovider);
        console.log('getCoinbase')
        let userAdd = await web3.eth.getCoinbase();
        console.log("userAdd",userAdd)
        let cwStorageAddress = await this.cwStorage(userAdd);
        console.log("cwStorageAddress",cwStorageAddress)
        await this.getCoinAddress(userAdd)
        await this.getPercent(userAdd)
        await this.getRefer(userAdd);
        await this.sign(userAdd)
        console.log("updateUserAdd",userAdd)
        this.$store.dispatch('updateUserAdd', userAdd)
        loading.close()
      } catch (error) {
        console.error(error)
        loading.close()
        this.$message.error(i18n.t('components_header_5'))
      }
    },
    async sign(userAdd){
      if(location.href.includes("localhost"))return
      const accounts = [userAdd];
    let salt = new Date().getTime();
    let msg = i18n.t('components_header_6');
    let messageHash = Web3.utils.stringToHex(msg);
    // if(window.ethereum.isTokenPocket) {
      let messageDigest = ethers.hashMessage(msg);
      // let sign = await ethers.signMessage(messageDigest);
      let signature = await new Web3(window.myprovider).eth.personal.sign(messageHash, accounts[0], salt.toString());
    //  console.log(messageHash, messageDigest, " vs");
      let add = await this.getSignerAddress(signature, messageDigest);
      if(add.toLowerCase() !== accounts[0].toLowerCase()){
        throw new Error(i18n.t('components_header_7'))
      }
    },
    async getSignerAddress(signature, message) {
        // const provider = new ethers.BrowserProvider(window.ethereum); // 或者其他任何提供者URL
        try {
          // const signer = await provider.getSigner();
            const signerAddress = await ethers.recoverAddress(message, signature);
            return signerAddress;
        } catch (error) {
            console.error("Failed to recover signer address:", error, error.message);
            return null;
        }
      },
    async cwStorage(userAdd){
      let cwStorageAddress = await getClockWiseContract().methods.cwStorage().call({from:userAdd});
      console.log("cwStorageAddress",cwStorageAddress)
      window.cwStorageAddress = cwStorageAddress;
      localStorage.setItem("cwStorageAddress",cwStorageAddress)
      return cwStorageAddress;
    },

    async getCoinAddress(userAdd){
      try {
        let usdtTokenAddress = await getClockWiseStorageContract().methods.usdtToken().call({from:userAdd});
        let preTokenAddress = await getClockWiseStorageContract().methods.preToken().call({from:userAdd});
        if(usdtTokenAddress&&preTokenAddress){
          window.usdtTokenAddress=usdtTokenAddress;
          window.preTokenAddress=preTokenAddress;
        }else{
          throw new Error("获取币地址失败")
        }
      } catch (error) {
        alert(error&&error.message||"获取币地址失败")
        window.location.reload();
      }
    },
    async getPercent(userAdd){
      try {
        let config = await getClockWiseStorageContract().methods.config(configList.indexOf("SwapTokenPairToken")).call({from:userAdd});
        if(config){
          window.SwapTokenPairToken=config;
          console.log("window.SwapTokenPairToken",window.SwapTokenPairToken)
        }else{
          throw new Error("获取币地址失败")
        }
      } catch (error) {
        alert(error&&error.message||"获取币地址失败")
        window.location.reload();
      }
    },
    async getRefer(userAdd){
      console.log('getRefer')
      let refer = sessionStorage.getItem("refer")||"";
      refer = refer.replace("#","").replace("\/","")
      if(!refer || (refer && !web3.utils.isAddress(refer))){
        refer = await getClockWiseStorageContract().methods.userAddr(1).call({from:userAdd})
        console.log('getRefer',refer)
      }
      window.refer = refer;
    },
    async Disconnect() {
      await window.myprovider.request({
        "method": "wallet_revokePermissions",
        "params": [
          {
            "eth_accounts": {
              "newKey": "New Value"
            }
          }
        ]
      });
      this.userAdd = null
      this.$store.dispatch('updateUserAdd', null)
    },
    goPage(e) {
      var href = e.currentTarget.dataset.href
      if (href == 'whitepaper') {
        window.open('/whitepaper.pdf')
      } else {
        this.$router.push(href)
      }
    }
  }
}
</script>
<style lang="scss" src="@/scss/common.scss" />
<style lang="scss" scoped>
.showMenu {
  transform: translateX(0);
  transition: transform .15s ease-in-out;
}

.hideMenu {
  transform: translateX(-100vw);
  transition: transform .15s ease-in-out;
}

.group_1 {
  background-color: rgba(8, 20, 24, 1);
  height: 4.694rem;
  margin-right: 0.027rem;
  padding: 0.374rem 0.027rem 0 0;

  .nav-bar_1 {
    margin-top: 0.614rem;
    padding: 0.427rem 0.987rem 0.347rem .567rem;

    .image_1 {
      width: 1.867rem;
      height: 2.16rem;
      margin-bottom: 0.054rem;
    }

    .text-wrapper_1 {
      background-color: rgba(22, 42, 47, 1);
      border-radius: 45px;
      border: 1px solid;
      width: 11rem;
      margin: 0 .4rem;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-left: .6rem;

      .text_2 {
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 0.8rem;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        text-align: right;
        white-space: nowrap;
        line-height: 1.12rem;
      }
    }

    .box_1 {
      margin: 0.134rem 0 0 0.56rem;

      .image-wrapper_1 {
        width: 4.694rem;

        .icon_1 {
          width: 2.08rem;
          height: 2.08rem;
        }

        .icon_2 {
          width: 2.08rem;
          height: 2.08rem;
        }
      }
    }
  }
}
</style>