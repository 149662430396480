import i18n from '../locales/index'

function copyToClipboard(text) {
    const input = document.createElement('textarea');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
}
function formatDate(date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hour = ('0' + date.getHours()).slice(-2);
    const minute = ('0' + date.getMinutes()).slice(-2);
    const second = ('0' + date.getSeconds()).slice(-2);
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}
function abiOutputlistToObject(outputlist) {
    let obj = {};
    outputlist.forEach(element => {
        obj[element.name]=element.type;
    });
    return obj;
}
async function waitUtil(millsconds,utilFunc) {
    return new Promise((resolve,reject)=>{
        let t = 0;
        if(!utilFunc){
            setTimeout(() => {
                resolve()
            }, millsconds);
            return
        }
        if(utilFunc()){
            console.log('waitUtil 1')
            resolve()
            return
        }
        let timer = setInterval(() => {
            t+=100;
            if(t>millsconds){
                clearInterval(timer);
                if(utilFunc()){
                    console.log('waitUtil 2')
                    resolve()
                }else{
                    console.log('waitUtil 3')
                    resolve()
                }
            }
            if(utilFunc()){
                console.log('waitUtil 4')
                resolve()
                clearInterval(timer);
            }
        }, 100);
    })
}
export {
    copyToClipboard,formatDate,abiOutputlistToObject,waitUtil
}