import i18n from '../locales/index'
import Vuex from "vuex"
import Vue from 'vue'
Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        count: 0,
        userAdd: ''
    },
    mutations: {
        increment (state ) {
            state.count++
        },
        setUserAdd(state, address) {
            state.userAdd = address;
        }
    },
      actions: {
        updateUserAdd({ commit }, address) {
          commit('setUserAdd', address);
        }
      }
})
export default store;