<template>
    <div class="pageAssets flex-col">
      <div class="box_3 flex-col">
        <div class="image-text_1 flex-row justify-between">
          <img
            class="image_1"
            referrerpolicy="no-referrer"
            src="@/assets/SketchPng978d3889c18f054a63e09bfd1e144aff63a3bfbaaf2abb2ab049da9748eed2a9.png"
          />
          <span class="text-group_1">{{$t('components_menu_10')}}</span>
        </div>
        <div class="box_4 flex-row justify-between">
          <div class="image-text_2 flex-row justify-between">
            <img
              class="icon_4"
              referrerpolicy="no-referrer"
              src="@/assets/SketchPng34edf7e8ca7c0d117d6ecad0a2ca6409eacd91b9291237d159f8a83d5fc65ab6.png"
            />
            <span class="text-group_2">{{$t('views_myassets_index_62')}}</span>
          </div>
          <button class="button_1 flex-col" @click="withdrawStaticToken" :style="{opacity:withdrawStaticTokenEnable?1:0.5}">
            <span class="text_3">{{$t('views_myassets_index_63')}}</span>
            <!-- <img
              class="icon_5"
              referrerpolicy="no-referrer"
              src="@/assets/SketchPng015a3e9e798038889f166c64aa48762109e703d80fe506aedbda82224b657096.png"
            /> -->
          </button>
        </div>
      </div>
      <div class="box_5 flex-col">
        <div class="block_1 flex-col">
          <div class="box_6 flex-row justify-between">
            <div class="image-text_3 flex-row justify-between">
              <img
                class="icon_6"
                referrerpolicy="no-referrer"
                src="@/assets/e0957dfe5b7742928bf81d64906d6057_mergeImage.png"
              />
              <span class="text-group_3">{{$t('components_menu_10')}}</span>
            </div>
            <div class="image-text_4 flex-row justify-between">
              <span class="text-group_4" @click="$router.push({'name':'receiverecord'})">{{$t('views_myassets_index_64')}}</span>
              &nbsp;
              <img
                class="icon_7"
                referrerpolicy="no-referrer"
                src="@/assets/SketchPng1f9a53fffe18ed581e3b21c787b8ac872612a8aeded8fc994fe70f6cd5a08f37.png"
              />
            </div>
          </div>
          <div class="box_7 flex-row justify-between">
            <img
              class="icon_8"
              referrerpolicy="no-referrer"
              src="@/assets/SketchPng35aba9edcdc4f289f721598431af6af3187398c974465c0a50a098dd8281d544.png"
            />
            <span class="text_4">{{$t('views_myassets_index_65')}}</span>
          </div>
          <div class="text-wrapper_2">
            <span class="text_5">{{profitRewardDetails.total|coin}}</span>
          </div>
          <button class="button_2 flex-col" @click="withdrawReleaseToken" :style="{opacity:withdrawReleaseTokenEnable?1:0.5}">
            <span class="text_8">{{$t('views_myassets_index_63')}}</span></button
          ><span class="text_9">{{$t('views_myassets_index_66')}}{{ userInfo.pendingDynamic|coin }}</span>
        </div>
      </div>
      <div class="box_8 flex-col">
        <div class="block_2 flex-row">
          <div class="image-text_5 flex-row justify-between">
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="@/assets/dd50f7341f7747dab4377a9e5db41b0b_mergeImage.png"
            />
            <span class="text-group_5">{{$t('views_myassets_index_67')}}</span>
          </div>
        </div>
      </div>
      <div class="box_9 flex-col">
        <div class="text-wrapper_3 flex-row justify-between">
          <span class="text_10">{{$t('views_myassets_index_68')}}</span>
          <span class="text_11">{{profitRewardDetails.lottery|coin}}</span>
        </div>
        <div class="text-wrapper_4 flex-row justify-between">
          <span class="text_12">{{$t('views_myassets_index_69')}}</span>
          <span class="text_13">{{profitRewardDetails.direct|coin}}</span>
        </div>
        <div class="text-wrapper_5 flex-row justify-between">
          <span class="text_14">{{$t('views_myassets_index_70')}}</span>
          <span class="text_15">{{profitRewardDetails.interPush|coin}}</span>
        </div>
        <div class="text-wrapper_7 flex-row justify-between">
          <span class="text_18">{{$t('views_myassets_index_71')}}</span>
          <span class="text_19">{{profitRewardDetails.star|coin}}</span>
        </div>
        <div class="text-wrapper_8 flex-row justify-between">
          <span class="text_20">{{$t('views_myassets_index_72')}}</span>
          <span class="text_21">{{profitRewardDetails.dividends|coin}}</span>
        </div>
        <div class="text-wrapper_9 flex-row justify-between">
          <span class="text_22">{{$t('views_myassets_index_73')}}</span>
          <span class="text_23">{{profitRewardDetails.flatLevel|coin}}</span>
        </div>
        <div class="text-wrapper_6 flex-row justify-between">
          <span class="text_16">{{$t('views_myassets_index_74')}}</span>
          <span class="text_17">{{profitRewardDetails.bouns|coin}}</span>
        </div>
      </div>
      <appFooter></appFooter>
    </div>
  </template>
<script>
import i18n from '../../locales/index'

import clock_wise from "@/config/clock_wise";
import test_coin from "@/config/test_coin";
import { mapState } from "vuex";
import Web3 from "web3";
import { getClockWiseContract, getClockWiseStorageContract, getCoinContract } from "@/utils/contract";
import { copyToClipboard } from "@/utils";
export default {
  computed: {
    ...mapState(['userAdd']),
    inviteLink() {
      return `${window.location.href}?refer=${this.userAdd}`;
    },
    withdrawReleaseTokenEnable(){
      return this.profitRewardDetails.total>0n&&this.userInfo.pendingDynamic>0n;
    }
  },
  watch: {
    userAdd() {
      this.initData();
    },
  },
  data() {
    return {
      userInfo: {},
      profitRewardDetails :{},
      withdrawStaticTokenEnable:false,//能否领取
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      if (!this.userAdd) return;
      console.log('initData')
      this.getUserInfo();
      this.profitReward();
      this.getPledge();
    },
    async withdrawStaticToken() {
      if(!this.withdrawStaticTokenEnable)return;
      let loading = this.$loading()
      getClockWiseContract().methods.withdrawStaticToken().send({ from: this.userAdd }).then((r) => {
        this.$message.success(i18n.t('views_myassets_index_75'));
        this.profitReward();
        this.withdrawStaticTokenEnable=false;
        loading.close();
      }).catch(e=>{
        this.$message.error(i18n.t('views_myassets_index_76'));
        loading.close()
      })
    },
    async withdrawReleaseToken() {
      if(!this.withdrawReleaseTokenEnable)return;
      let loading = this.$loading()
      getClockWiseContract().methods.withdrawReleaseToken().send({ from: this.userAdd }).then((r) => {
        this.$message.success(i18n.t('views_myassets_index_75'));
        this.profitReward();
        //this.withdrawReleaseTokenEnable=false;
        loading.close();
      }).catch(e=>{
        this.$message.error(i18n.t('views_myassets_index_76'));
        loading.close()
      })
    },
    async getPledge(loading) {
      try {
        let r = await getClockWiseStorageContract().methods.getUserPledge(this.userAdd).call({from:this.userAdd});
        console.log("getUserPledge",r)
        r.forEach(_pledge=>{
          if(!_pledge.isLock && !_pledge.isClose && !_pledge.isEnd && !_pledge.isExactStatic) {
            this.withdrawStaticTokenEnable = true;
          }
        })
        loading&&loading.close()
      } catch (error) {
        loading&&loading.close();
      }
    },
    onClick_2() {
      alert(1);
    },
    copyInviteLink() {
      copyToClipboard(this.inviteLink)
    },
    profitReward() {
      getClockWiseStorageContract().methods.profitReward(this.userAdd).call({ from: this.userAdd }).then((r) => {
        this.profitRewardDetails = r;
        console.log(r)
      })
    },
    getUserInfo() {
      getClockWiseStorageContract().methods.users(this.userAdd).call({ from: this.userAdd }).then((r) => {
        this.userInfo = r;
      })
    },
  },
};
</script>
<style lang="scss" src="@/scss/common.scss" />
<style lang="scss" src="./index.scss" />