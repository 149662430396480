import i18n from '../locales/index'

console.log(window.location.href)
function getUrlVars(name) {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
        function (m, key, value) {
            vars[key] = value;
        }
    );
    console.log(JSON.stringify(vars))
    return vars[name];
}
console.log('getUrlVars(\'refer\')')
console.log(getUrlVars('refer'))
if(getUrlVars('refer')){
  sessionStorage.setItem('refer',getUrlVars('refer'));
}