<template>
    <el-dialog :title="$t('views_home_components_bind_dialog_39')" :close-on-click-modal="false" :visible.sync="visible" width="70%" :show-close="false">
        <span>
            <el-form>
                <el-form-item :label="$t('views_home_components_bind_dialog_40')" size="mini">
                    <el-input type="textarea" v-model="refer" :placeholder="$t('views_home_components_bind_dialog_40')" size="mini" clearable></el-input>
                </el-form-item>
            </el-form>
        </span>
        <span slot="footer">
            <el-button type="success" size="small" @click="submit">OK</el-button>
        </span>
    </el-dialog>
</template>
<script>
import i18n from '../../../locales/index'

import { getClockWiseContract } from "@/utils/contract";
import web3 from "@/utils/myweb3";
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(['userAdd'])
    },
    data() {
        return {
            visible: true,
            refer: window.refer,
        }
    },
    created() {
    },
    methods: {
        submit() {
            if (!web3.utils.isAddress(this.refer)) {
                this.$message.error(i18n.t('views_home_components_bind_dialog_41'))
                return
            }
            let loading = this.$loading();
            getClockWiseContract().methods.register(this.refer).send({ from: this.userAdd }).then((r) => {
                loading.close();
                console.log(r)
                this.visible = false;
                this.$emit("success")
                this.$message.success(i18n.t('views_home_components_bind_dialog_42'));
            }).catch((e)=>{
                console.error(e)
                loading.close()
            })
            // 限流 报错
            // clockContract.events.BindRefer({fromBlock: 0}, function(error, event) {
            //     console.log(error, event);
            // })
        }
    }
}
</script>