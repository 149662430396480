import i18n from './/index'
import VueI18n from 'vue-i18n'
import Vue from 'vue'
import zh from './zh_cn.json'
import tw from './tw.json'
import er from './er.json'
import en from './en.json'
import eleLocale from 'element-ui/lib/locale';
eleLocale.i18n((key, value) => i18n.t(key, value));
Vue.use(VueI18n)

export default new VueI18n({
	locale: 'en',
	messages: {
		zh,en,er,tw
	}
})
