<template>
  <div class="flex-col align-center">
    <div class="block_2 flex-row justify-between">
      <div class="image-text_1 flex-row justify-between">
        <img class="image_2" referrerpolicy="no-referrer"
          src="@/assets/SketchPng978d3889c18f054a63e09bfd1e144aff63a3bfbaaf2abb2ab049da9748eed2a9.png" />
        <span class="text-group_2">{{$t('views_rankingyesterday_index_79')}}</span>
      </div>
    </div>
    <div class="group_6 flex-col">
      <div class="text-wrapper_8 tablehead flex-row">
        <span class="text_22">{{$t('views_home_index_59')}}</span> <span class="text_23">{{$t('views_home_index_60')}}</span>
        <span class="text_24">{{$t('views_home_index_61')}}</span>
        <span class="text_24">{{$t('views_rankingyesterday_index_80')}}</span>
      </div>
      <div class="text-wrapper_8 flex-row" v-for="(x,i) in loopData0" :key="x">
        <span class="">{{i+1}}</span> <span class="">{{x.account|shortAddr}}</span>
        <span class="">{{x.score|coin}}</span>
        <span class="">{{x.receivedAmount|coin}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import i18n from '../../locales/index'

import { getClockWiseContract, getClockWiseStorageContract } from '@/utils/contract';
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(["userAdd"])
  },
  data() {
    return {
      loopData0: [
      ],
      constants: {},
    };
  },
  watch: {
    userAdd() {
      this.getHistory();
    }
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    async getHistory() {
      if(!this.userAdd)return;
      let r = await getClockWiseStorageContract().methods.getHistory(1).call({from:this.userAdd});
      console.log("rtt",r)
      r = r.filter(e =>{
         if(e.account == '0x0000000000000000000000000000000000000000')return false;
         console.log(e)
         return true;
      });
      this.loopData0.push(...r)
    },
  },
};
</script>
<style lang="scss" src="@/scss/common.scss" />
<style lang="scss" scoped src="./index.scss">