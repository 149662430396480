<template>
  <div class="flex-col align-center">
    <div class="block_2 flex-row justify-between">
      <div class="image-text_1 flex-row">
        <img class="image_2" referrerpolicy="no-referrer"
          src="@/assets/SketchPng978d3889c18f054a63e09bfd1e144aff63a3bfbaaf2abb2ab049da9748eed2a9.png" />
        <span class="text-group_2">{{$t('views_receiverecord_index_81')}}</span>
      </div>
    </div>
    <div class="group_6 flex-col">
      <div class="text-wrapper_7 flex-row">
        <span class="text_22">{{$t('views_receiverecord_index_82')}}</span>
        <span class="text_24">{{$t('views_mycontribution_index_78')}}</span>
        <span class="text_24">{{$t('views_receiverecord_index_83')}}</span>
      </div>
      <div class="text-wrapper_8 flex-row" v-for="(x,i) in loopData0" :key="x">
        <span class="">{{i+1}}</span>
        <span class="">{{x.times|date}}</span>
        <span class="">{{x.amount|coin}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import i18n from '../../locales/index'

import { getClockWiseContract, getClockWiseStorageContract } from '@/utils/contract';
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(["userAdd"])
  },
  watch: {
    userAdd() {
      this.getReceiveRecord();
    }
  },
  data() {
    return {
      loopData0: [
      ],
      constants: {},
    };
  },
  mounted() {
    this.getReceiveRecord();
  },
  methods: {
    async getReceiveRecord() {
      let r = await getClockWiseStorageContract().methods.getReceiveRecord(this.userAdd).call({from:this.userAdd});
      console.log(r)
      this.loopData0.push(...r.reverse())
    },
  },
};
</script>
<style lang="scss" src="@/scss/common.scss" />
<style lang="scss" scoped src="./index.scss">