import { createPublicClient, http, encodePacked } from 'viem'

import { SmartRouter, V4Router } from '@pancakeswap/smart-router'
import { bscTokens , bscTestnetTokens} from '@pancakeswap/tokens'
import { Native, ChainId, CurrencyAmount, TradeType, ERC20Token,Percent } from '@pancakeswap/sdk'
import { bsc,bscTestnet } from 'viem/chains'

const V2_FEE_PATH_PLACEHOLDER = 8388608;
var PoolType={
    V2:0,
    V3:1,
    STABLE:2,
}
function isV2Pool(pool) {
    return pool.type === PoolType.V2
}

function isV3Pool(pool) {
    return pool.type === PoolType.V3
}

function encodeMixedRouteToPath(route, exactOutput) {
    const firstInputToken = route.pools[0].token0

    const { path, types } = route.pools.reduce(
        (
            { path, types },
            pool,
            index,
        ) => {
            const outputToken = pool.token1
            const fee = isV3Pool(pool) ? pool.fee : V2_FEE_PATH_PLACEHOLDER
            if (index === 0) {
                return {
                    inputToken: outputToken,
                    types: ['address', 'uint24', 'address'],
                    path: [firstInputToken.address, fee, outputToken.address],
                }
            }
            return {
                inputToken: outputToken,
                types: [...types, 'uint24', 'address'],
                path: [...path, fee, outputToken.address],
            }
        },
        { inputToken: firstInputToken, path: [], types: [] },
    )

    return exactOutput ? encodePacked(types.reverse(), path.reverse()) : encodePacked(types, path)
}

async function encodeMixedRoute(amount_) {
    console.log("amount_",amount_)

    let client = createPublicClient({
        chain: bsc,
        transport: http('https://bsc-dataseed4.ninicoin.io'),
        batch: {
            multicall: {
                batchSize: 1024 * 200,
            },
        },
    })

    if(!window.mainNet){
        client = createPublicClient({
            chain: bscTestnet,
            transport: http('https://data-seed-prebsc-1-s2.bnbchain.org:8545'),
            batch: {
                multicall: {
                    batchSize: 1024 * 200,
                },
            },
        })
    }
    let swapFrom = Object.values(window.mainNet?bscTokens:bscTestnetTokens).
    find(d=> d.address.toLowerCase() == window.usdtTokenAddress.toLowerCase());

    let swapTo = Object.values(window.mainNet?bscTokens:bscTestnetTokens)
    .find(d=> d.address.toLowerCase() == window.preTokenAddress.toLowerCase());
    
    console.log("swapFrom",swapFrom)
    console.log("swapTo",swapTo)
    if(!swapFrom){
        swapFrom = new ERC20Token(
            window.mainNet?0x38:0x61,
            window.usdtTokenAddress,
            18,
            '-',
            '-',
            window.mainNet?'https://www.binance.com/':'https://pancakeswap.finance/',
        )
    }
    if(!swapTo){
        swapTo = new ERC20Token(
            window.mainNet?0x38:0x61,
            window.preTokenAddress,
            18,
            'CAKE1',
            'PancakeSwap Token1',
            window.mainNet?'https://www.binance.com/':'https://pancakeswap.finance/',
        )
    }
    console.log("swapFrom",swapFrom)
    console.log("swapTo",swapTo)
    // const swapTo = new ERC20Token(
    //     ChainId.BSC,
    //     '0x2b5d9adea07b590b638ffc165792b2c610eda649',
    //     18,
    //     'CKP',
    //     'CKP',
    //     'https://www.binance.com/',
    // )
    // console.log('swapFrom', swapFrom)
    // console.log('client', client)

    const v3Pools = await V4Router.getV3CandidatePools({
        clientProvider: () => client,
        currencyA: swapFrom,
        currencyB: swapTo,
    })
    //if(1==1)return

    // 0.01 BNB in our example
    const amount = CurrencyAmount.fromRawAmount(swapFrom, amount_)

    const trade = await V4Router.getBestTrade(amount, swapTo, TradeType.EXACT_INPUT, {
        gasPriceWei: () => client.getGasPrice(),
        candidatePools: v3Pools,
    })
     console.log('trade', trade, amount)
    // const minOut = SmartRouter.minimumAmountOut(trade,new Percent(trade.routes[0].percent), trade.outputAmount).quotient;
    let minOut = trade.outputAmount.numerator;
     // console.log('encodeMixedRouteToPath', encodeMixedRouteToPath(trade.routes[0], false))
    // console.log('encodeMixedRouteToPath', encodeMixedRouteToPath(trade.routes[0], true))
    //const minOut = SmartRouter  .minimumAmountOut(trade,new Percent(routes[route].percent), outputAmount).quotient;
    let path = encodeMixedRouteToPath(trade.routes[0], false);
    minOut = minOut*995n/1000n;
    console.log("{path,minOut}",{path,minOut})
    return {path,minOut};
}

window.encodeMixedRoute = encodeMixedRoute
export default encodeMixedRoute;