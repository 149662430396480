<template>
<div class="text-group_6 flex-col">
    <span class="text_49">CLOCKWISE &#64;2024~2030</span>
    <span class="text_50">{{$t('base_on')}}</span>
  </div>
</template>
<script>
import i18n from '../locales/index'


export default {
  name: 'appfooter',
  methods:{
  }
}
</script>
<style lang="scss" scoped>
.text-group_6 {
    padding: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
          .text_49 {
        height: 0.747rem;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 0.746rem;
        font-family: PangMenZhengDao;
        font-weight: normal;
        text-align: right;
        white-space: nowrap;
        line-height: 0.854rem;
      }
      .text_50 {
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 0.64rem;
        font-weight: normal;
        text-align: right;
        white-space: nowrap;
        line-height: 0.88rem;
        margin-top: 0.214rem;
      }
    }
</style>