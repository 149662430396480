import i18n from '../locales/index'
import clock_wise from "@/config/clock_wise";
import test_coin from "@/config/test_coin";
import clock_wise_storage from "@/config/clock_wise_storage";
import Web3 from "web3";
//业务合约
function getClockWiseContract(){
    var web3 = new Web3(window.myprovider);
    return new web3.eth.Contract(clock_wise.abi,clock_wise.address)
}
//业务数据存储合约
function getClockWiseStorageContract(){    
    var web3 = new Web3(window.myprovider);
    console.log('localStorage.getItem("cwStorageAddress")',localStorage.getItem("cwStorageAddress"))
    return new web3.eth.Contract(clock_wise_storage.abi,localStorage.getItem("cwStorageAddress"))
}
//代币合约
function getCoinContract(){    
    var web3 = new Web3(window.myprovider);
    return new web3.eth.Contract(test_coin.abi,window.usdtTokenAddress)
}

export {
    getClockWiseContract,
    getClockWiseStorageContract,
    getCoinContract
}