import i18n from './locales/index'
import Vue from 'vue'
import { formatDate } from './utils';
import Web3 from 'web3';
Vue.filter('date',(date)=>{
    if(!date)return '';
    if(typeof date==='bigint'){
        return formatDate(new Date(parseInt(date)*1000))
    }
    return formatDate(new Date(parseInt(date)))
})
Vue.filter('coin',(amount)=>{
    if(amount===BigInt(0))return 0;
    if(amount===''||amount===null||amount===undefined)return '';
    if(amount=='-')return amount;
    let r = Web3.utils.fromWei(amount,'ether');
    // 如果小数点后面大于2位 就只留2位
    if(r.includes(".")){
        let splited = r.split(".");
        if(splited[1].length>2){
            splited[1] = splited[1].substring(0,2);
            r = splited.join(".");
        }
    }
    return r;
})
Vue.filter('shortAddr',(addr)=>{
    if(!addr)return '';
    if(addr=='-')return addr;
    addr+='';
    return addr.substr(0, 5) + "..." + addr.substr(-5);
})