<template>
    <div class="container">
        <h1>Account</h1>
        <el-button type="primary" size="small" @click="createAccount">createAccount</el-button>
        <el-button type="primary" size="small" :disabled="!privateKey" @click="privateKeyToAccount">privateKeyToAccount</el-button>
        <el-button type="primary" size="small" @click="createWallet(1)">createWallet1Account</el-button>
        <el-button type="primary" size="small" @click="createWallet(2)">createWallet2Account</el-button>
        <el-button type="primary" size="small" @click="encryptWallet">encryptWallet</el-button>
        <el-button type="primary" size="small" @click="decryptWallet">decryptWallet</el-button>
        <el-button type="primary" size="small" @click="loadWallet">loadWallet</el-button>
        <el-button type="primary" size="small" @click="saveWallet">saveWallet</el-button>
        <el-button type="primary" size="small" @click="sendTransaction">sendTransaction</el-button>
        <el-button type="primary" size="small" @click="sendTransactionWithHardhat">sendTransactionWithHardhat</el-button>

    </div>
</template>
<script>
import i18n from '../../../locales/index'

import hardhatWeb3 from '@/utils/hardhat_web3';
import infuraWeb3 from '@/utils/infura';
import web3 from '@/utils/myweb3';
import { mapState } from 'vuex';

export default {
    computed:{
        ...mapState(["userAdd"])
    },
    data(){
        return {
            privateKey:null,
        }
    },
    methods:{
        createAccount(){
            let account = infuraWeb3.eth.accounts.create();
            this.$alert(i18n.t('views_examples_components_account_30', [account.address]))
            this.privateKey = account.privateKey;
        },

        privateKeyToAccount(){
            let account = infuraWeb3.eth.accounts.privateKeyToAccount(this.privateKey);
            this.$alert(i18n.t('views_examples_components_account_31', [this.privateKey,account.address]))
        },
        createWallet(numberOfAccounts){
            let wallet = web3.eth.accounts.wallet.create(numberOfAccounts)
            window.wallet = wallet;
           // wallet.encrypt()
           
            console.log(`创建的钱包`,wallet)
            this.$alert(i18n.t('views_examples_components_account_32', [wallet.map(e=>e.address).join(",")]))
        },
        async encryptWallet(){
            if(!window.wallet){
                this.$message.error(i18n.t('views_examples_components_account_33'))
                return
            }
            window.keyStoreList = await window.wallet.encrypt("abc");
            this.$message(i18n.t('views_examples_components_account_34'))
            console.log(window.keyStoreList)
        },
        async decryptWallet(){
            if(!window.keyStoreList){
                this.$message.error(i18n.t('views_examples_components_account_35'))
                return
            }
            
            let wallet = await web3.eth.wallet.decrypt(window.keyStoreList,"abc");
            this.$message(i18n.t('views_examples_components_account_36'))
            console.log(wallet)
        },
        async loadWallet(){
            let wallet = await web3.eth.wallet.load("abc");
            this.$message(i18n.t('views_examples_components_account_37'))
            console.log(wallet)
        },
        async saveWallet(){
            if(!window.wallet){
                this.$message.error(i18n.t('views_examples_components_account_33'))
                return
            }
            await window.wallet.save("abc");
            this.$message(i18n.t('views_examples_components_account_38'))
        },
        async sendTransaction(){
            infuraWeb3.eth.accounts.wallet.add(infuraWeb3.eth.accounts.privateKeyToAccount("0x68b776c63ae03306b28d447b0d03bb76d579bceca75afd2317e4083db290a41c"))
            let tx = {
                from:this.userAdd,
                to:'0x9311fD3FDF50e4CE8723A0e14F8b4E5cC01E3eDe',
                value:web3.utils.toWei("0.001",'ether')
            }
            let signedTx = await infuraWeb3.eth.accounts.wallet[0].signTransaction(tx)
            // 报错 eth_estimateGas参数infura只接受1个 实际传2个 可能web3版本问题
            infuraWeb3.eth.sendTransaction(tx)
        },
        async sendTransactionWithHardhat(){
            hardhatWeb3.eth.accounts.wallet.add(hardhatWeb3.eth.accounts.privateKeyToAccount("0xac0974bec39a17e36ba4a6b4d238ff944bacb478cbed5efcae784d7bf4f2ff80"))
            let block = await hardhatWeb3.eth.getBlock('latest')
            let tx = {
                from:'0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
                to:'0x70997970C51812dc3A010C7d01b50e0d17dc79C8',
                value:web3.utils.toWei("1",'ether'),
                // gas: block.gasLimit,
                // gasPrice: 20000000,
                // maxPriorityFeePerGas:20000000,
            }
            //hardhatWeb3.eth.sendTransaction()
            //let signedTx = await hardhatWeb3.eth.accounts.wallet[0].signTransaction(tx)
            // 报错 eth_estimateGas参数infura只接受1个 实际传2个 可能web3版本问题
            hardhatWeb3.eth.sendTransaction(tx)
            setTimeout(() => {
                hardhatWeb3.eth.getBalance('0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266').then(console.log)
            }, 2000);
        },
    }
}
</script>
<style lang="scss" scoped>
.container{
    border-bottom: 2px dashed #ccc;
    padding-bottom: 1rem;
    margin: 1rem;
}
.container *{
    font-size: 1rem;
    color: white;
}
.container button{
    margin-bottom: .3rem;
}
</style>