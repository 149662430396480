<template>
  <div>
    <div class="pageHome flex-col">
      <div class="group_3 flex-row justify-between">
        <img class="label_4" referrerpolicy="no-referrer"
          src="@/assets/SketchPng2e83dc6485d60a9d37bf56f101f7a1aba7a485214a6c3e4e09417085d46ac545.png" />
        <span
          class="text_3">{{$t('views_home_index_51')}} <br v-if="$i18n.locale=='tw'"><br>{{$t('views_home_index_52')}}<br v-if="$i18n.locale=='tw'"><br>{{$t('views_home_index_53')}}</span>
        <div class="group_4 flex-col">
          <div class="text-group_1 flex-col">
            <span class="text_4">CLOCKWISE</span>
            <span class="paragraph_1" v-html='$t("desc1")'></span>
          </div>
          <button class="button_1 flex-col">
            <span class="text_5">{{$t("join")}}</span>
          </button>
        </div>
      </div>
      <div class="group_5 flex-col">
        <span class="text_6">{{$t('views_home_index_54')}}</span>
        <div class="text-wrapper_2 flex-row">
          <span class="text_7">{{$t('views_home_index_55')}}</span> <span class="text_8">+{{ accelerate }}%</span>
        </div>
        <div class="block_1 flex-row justify-between">
          <button class="button_2 flex-col" @click="$refs.pledge.visible = true">
            <span class="text_9">{{$t('views_home_components_pledge_43')}}</span></button>
          <!-- <button class="button_3 flex-col" @click="onClick_3">
          <span class="text_10">贡献历史</span>
        </button> -->
        </div>
        <a @click="$router.push({name:'mycontribution'})" style='color: #999;font-size: .8rem;margin-top: .5rem;text-decoration: none;padding-left:1rem;'>{{$t('views_home_index_56')}}</a>
        <div class="block_2 flex-row justify-between">
          <div class="image-text_1 flex-row justify-between">
            <img class="image_2" referrerpolicy="no-referrer"
              src="@/assets/SketchPng978d3889c18f054a63e09bfd1e144aff63a3bfbaaf2abb2ab049da9748eed2a9.png" />
            <span class="text-group_2">{{$t('views_home_index_57')}}</span>
          </div>
          <div class="image-text_2 flex-row justify-between">
            <span class="text-group_3" @click="$router.push({ name: 'rankingyesterday' })">{{$t('views_home_index_58')}}</span>
            &nbsp;
            <img class="icon_3" referrerpolicy="no-referrer"
              src="@/assets/SketchPng1f9a53fffe18ed581e3b21c787b8ac872612a8aeded8fc994fe70f6cd5a08f37.png" />
          </div>
        </div>
        <div class="text-wrapper_3">
          <span class="text_11">TOP1 </span>
          <span class="text_12">{{ (rankingsOneToThree[0] ? rankingsOneToThree[0].account : '-') | shortAddr }}</span>
        </div>
        <div class="block_3 flex-row">
          <img class="image_3" referrerpolicy="no-referrer"
            src="@/assets/SketchPng9b94af42210a4541bfae91eac0d8de7c0921ca1188bbf187d9d0f444654bd35b.png" />
          <div class="section_1 flex-col">
            <div class="image-text_3 flex-row justify-between">
              <img class="label_5" referrerpolicy="no-referrer"
                src="@/assets/52243185f0be4f0ca4e9e19e185a0779_mergeImage.png" />
              <span class="text-group_4">TOP2</span>
            </div>
            <span class="text_13">{{ (rankingsOneToThree[1] ? rankingsOneToThree[1].account : '-') | shortAddr }}</span>
            <div class="text-wrapper_4">
              <span class="text_14">{{ (rankingsOneToThree[1] ? rankingsOneToThree[1].score : '-') | coin }}</span>
              <span class="text_15"></span>
            </div>
          </div>
          <div class="text-wrapper_5">
            <span class="text_16">{{ (rankingsOneToThree[0] ? rankingsOneToThree[0].score : '-') | coin }}</span> <span
              class="text_17"></span>
          </div>
          <div class="section_2 flex-col"></div>
          <div class="section_3 flex-col">
            <span class="text_18">TOP3</span>
            <span class="text_19">{{ (rankingsOneToThree[2] ? rankingsOneToThree[2].account : '-') | shortAddr }}</span>
            <div class="text-wrapper_6">
              <span class="text_20">{{ (rankingsOneToThree[2] ? rankingsOneToThree[2].score : '-') | coin }}</span>
              <span class="text_21"></span>
            </div>
          </div>
        </div>
        <img class="thumbnail_1" referrerpolicy="no-referrer"
          src="@/assets/b0fdfe14d4e543fab5e1dab99817df63_mergeImage.png" />
        <div class="image-wrapper_2 flex-col">
          <img class="image_4" referrerpolicy="no-referrer"
            src="@/assets/SketchPng605ab6a0b5ae0cd45f63cfd175a76a6b26344d7bd64aa9579ec7bb78b62b320d.png" />
        </div>
        <img class="image_5" referrerpolicy="no-referrer"
          src="@/assets/SketchPngea76827a06dfd414ae8323e196c3bc5b7a486fff2a4acdb91e71182aa0b87115.png" />
        <div class="block_4 flex-col">
          <div class="image-wrapper_3 flex-row justify-between">
            <img class="image_6" referrerpolicy="no-referrer"
              src="@/assets/SketchPng151fecd492f5f6694db29bbbfc299f436183cd6348a80033e6f482a8ec068097.png" />
            <img class="icon_4" referrerpolicy="no-referrer"
              src="@/assets/SketchPngd5cbf7fc0f76e320544d4eee35776095092b0eeae2092c7b5f966fab8c6bb8c8.png" />
          </div>  
        </div>
        <img class="image_7" referrerpolicy="no-referrer"
          src="@/assets/SketchPng02b6704239607c83dbf16becdfa76379e04f0f6161e353426c7dd01173a0b105.png" />
      </div>
      <div class="group_6 flex-col" v-if="rankingsFourAndLater.length > 0">
        <div class="text-wrapper_7 flex-row">
          <span class="text_22">{{$t('views_home_index_59')}}</span> <span class="text_23">{{$t('views_home_index_60')}}</span>
          <span class="text_24">{{$t('views_home_index_61')}}</span>
        </div>
        <div class="text-wrapper_8 flex-row" v-for="(item, i) in rankingsFourAndLater">
          <span class="text_25">{{ i + 4 }}</span> <span class="text_26">{{ item.account | shortAddr }}</span>
          <span class="text_27">{{ item.score | coin }}</span>
        </div>
        <!-- <div class="text-wrapper_14 flex-row">
        <span class="text_43">查看更多...</span>
      </div> -->
      </div>
      <div class="group_7 flex-col">
        <div class="image-text_4 flex-row justify-between">
          <img class="image_8" referrerpolicy="no-referrer"
            src="@/assets/SketchPng978d3889c18f054a63e09bfd1e144aff63a3bfbaaf2abb2ab049da9748eed2a9.png" />
          <span class="text-group_5">{{ $t("cooperative") }}</span>
        </div>
        <div class="box_2 flex-row justify-between">
          <div class="tag">
            <img class="image_9" referrerpolicy="no-referrer"
              src="@/assets/SketchPngfd2fb6150cefcec33e3ecb62474397bb1e95870294a6c38196c3ebb42a2caf99.png" />
              ClockWise
          </div>
          <div class="tag" style='font-size: .8rem;'>
            <img class="image_9" referrerpolicy="no-referrer"
              src="@/assets/original_1724484170333_31799b1ccab399884eaf5d4b1754b192.jpg" />
            CoinMarket
          </div>
          <div class="tag">
            <img class="image_9" referrerpolicy="no-referrer"
              src="@/assets/original_1724484278526_f202c3115a29ea4426e4611e4a9e4ef1.jpg" />
              GitHub
          </div>
          <div class="tag">
            <img class="image_9" referrerpolicy="no-referrer"
              src="@/assets/original_1724484074531_b7c868a002bdc48a9253958a9b1a1c26.jpg" />
              BINANCE
          </div>
          <div class="tag">
            <img class="image_9" referrerpolicy="no-referrer"
              src="@/assets/usdt.png" />
            Thther
          </div>
          <div class="tag">
            <img class="image_9" referrerpolicy="no-referrer"
              src="@/assets/aaa.png" />
            Pancakes
          </div>
          <!-- <div class="tag_3 flex-col">
            <img class="image_10" referrerpolicy="no-referrer"
              src="@/assets/original_1724484170333_31799b1ccab399884eaf5d4b1754b192.jpg" />
          </div>
          <div class="tag_3 flex-col">
            <img class="image_10" referrerpolicy="no-referrer"
              src="@/assets/original_1724484278526_f202c3115a29ea4426e4611e4a9e4ef1.jpg" />
          </div> -->
        </div>
        <div class="image-wrapper_4 flex-row">
          <img class="icon_5" referrerpolicy="no-referrer"
            v-if="false"
            src="@/assets/SketchPnga321e1ccd497d29e780454dccfe4ccdd2d73edd992042aaba7e84a3042ddc6c6.png" />
          <img class="label_6" referrerpolicy="no-referrer"
            onclick="window.open('https://twitter.com/clockwisechain','_')"
            src="@/assets/SketchPng084618db57cacc132af140714d4b0c3b369b83d8a211b98ddb9ca73715a5e784.png" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img class="image_11" referrerpolicy="no-referrer"
            v-if="false"
            src="@/assets/SketchPnge9016f76aba8209b9922210c1e15392fcdf42c37cfa2eaa7695ba269b91d7cdf.png" />
          <img class="image_11" referrerpolicy="no-referrer" @click="sendEmail" src="@/assets/email.png" />
        </div>
        <appFooter></appFooter>
      </div>
    </div>
    <Bind_dialog v-if="showBindDialog" @success="onRegSuccess"></Bind_dialog>
    <pledge ref="pledge" :userInfo="userInfo" v-if="userInfo"></pledge>
  </div>
</template>
<script>
import i18n from '../../locales/index'

import clock_wise from "@/config/clock_wise";
import test_coin from "@/config/test_coin";
import { mapState } from "vuex";
import Web3, { FMT_BYTES, FMT_NUMBER } from "web3";
import Bind_dialog from "./components/bind_dialog.vue";
import pledge from "./components/pledge.vue";
import { getClockWiseStorageContract } from "@/utils/contract";
import web3 from "@/utils/myweb3";
import infuraWeb3 from "@/utils/infura";
import { abiOutputlistToObject } from "@/utils";
export default {
  computed: {
    ...mapState(['userAdd']),
    rankingsOneToThree() {
      return this.rankings.slice(0, 3);
    },
    rankingsFourAndLater() {
      return this.rankings.slice(3);
    },
  },
  components: {
    Bind_dialog,
    pledge
  },
  watch: {
    userAdd() {
      if(this.$refs.pledge){
        this.$refs.pledge.visible = false;
      }
      this.showBindDialog = false;
      this.checkUser();
      this.initData();
    }
  },
  data() {
    return {
      constants: {},
      userInfo:{},
      allowanceMin: Web3.utils.toWei(1000, 'ether'),
      showBindDialog: false,//是否显示绑定弹窗
      showPledge: false,//贡献弹窗
      accelerate: '',//最高赚钱百分比
      rankings: [],
    };
  },
  mounted() {
    // try {
    //   fetch("http://103.255.47.131:8088/").then(console.log).catch(console.log)
    // } catch (error) {
    //   console.error(error)
    // }
    this.initData();
  },
  async created() {

  },
  methods: {
    async initData() {
      if (!this.userAdd) return;
      // 不采用批处理
      this.checkUser();      
      this.getRanking();
      this.accelerate = await getClockWiseStorageContract().methods.config(11).call({ from: this.userAdd });
      // 采用批处理 速度快3倍
      //this.initDataWithBatchRequest();
    },
    onRegSuccess() {
      this.checkUser()
    },
    initDataWithBatchRequest(){
      
      let batchRequest = new infuraWeb3.eth.BatchRequest();
      let contract = getClockWiseStorageContract()


      let checkUserReq = {
        id: "checkUserReq", method: "eth_call", jsonrpc: '2.0', params: [
          { to: clock_wise.address, data: contract.methods.users(this.userAdd).encodeABI(), from: this.userAdd },
          'latest'
        ]
      };

      let accelerateReq = {
        id: "accelerate", method: "eth_call", jsonrpc: '2.0', params: [
          { to: clock_wise.address, data: contract.methods.config(11).encodeABI(), from: this.userAdd },
          'latest'
        ]
      };

      let getRankingReq = {
        id: "getRankingReq", method: "eth_call", jsonrpc: '2.0', params: [
          { to: clock_wise.address, data: contract.methods.getRanking(0).encodeABI(), from: this.userAdd },
          'latest'
        ]
      };

      batchRequest.add(checkUserReq);
      batchRequest.add(accelerateReq);
      batchRequest.add(getRankingReq);
      batchRequest.execute({timeout:1000*3}).then(res => {
        res.forEach((r, i) => {
          if (i == 0) {
            let user = infuraWeb3.eth.abi.decodeParameter(
              { UserInfo: abiOutputlistToObject(clock_wise.abi.find(e => e.name == 'users').outputs) },
              r.result
            )
            let { isExist } = user;
            if (!isExist) {
              this.showBindDialog = true;
            }
            console.log('user', user)
          } else if (i == 1) {
            this.accelerate = infuraWeb3.eth.abi.decodeParameter(
              'uint256',
              r.result
            );
            console.log('accelerate', this.accelerate)
          } else if (i == 2) {
            let rankings = infuraWeb3.eth.abi.decodeParameter(
              clock_wise.abi.find(e => e.name == 'getRanking').outputs[0],
              r.result
            );

            console.log('rankings', rankings)
            this.rankings = rankings.filter(e => e.account != '0x0000000000000000000000000000000000000000');
            console.log('sendBillboard', JSON.stringify(this.rankings.map(e=>e.account)))
          }
        })
      });
    },
    async getRanking() {
      let scores = await getClockWiseStorageContract().methods.getScores().call({ from: this.userAdd });
      console.log('getScores', scores)
      let pawns = await getClockWiseStorageContract().methods.getPawns().call({ from: this.userAdd });
      console.log('getPawns', pawns)
      scores.forEach((e,i)=>{
        e.account = pawns[i];
      })
      scores.sort((a,b)=>new Number(b.score - a.score));
      scores = scores.slice(0,13)
      this.rankings = scores;
      // let r = await getClockWiseStorageContract().methods.getRanking(0).call({ from: this.userAdd });
      // console.log('rankings', r)
      // let rec={};
      // this.rankings = r.filter(e =>{
      //   if(e.account == '0x0000000000000000000000000000000000000000')return false;
      // });
    },
    sendEmail() {
      var link = 'mailto:clockwisechain@gmail.com'
       // + '?cc=copy@example.com'
        + '?subject=' + encodeURIComponent('Email Subject')
        + '&body=' + encodeURIComponent('Email Body Text');
      window.location.href = link;
    },
    // 检查用户注册没
    async checkUser() {
      return new Promise((resolve,reject)=>{
        try {
          this.userInfo=null;
          getClockWiseStorageContract().methods.users(this.userAdd).call({ from: this.userAdd }).then((userInfo) => {
            let { isExist ,refer } = userInfo;
            if (!isExist) {
              this.showBindDialog = true;
            }
            getClockWiseStorageContract().methods.users(refer).call({ from: this.userAdd }).then((referInfo) => {
              this.userInfo = userInfo;
              this.userInfo.referLevel = referInfo.level;
              console.log('referInfo', referInfo)
              console.log('userInfo', userInfo)
              resolve()
            })
          })
        } catch (error) {
          reject(error)
        }
      })
    },
  },
};
</script>
<style lang="scss" src="../../scss/common.scss" />
<style lang="scss" scoped src="./index.scss" />