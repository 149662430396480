<template>
    <el-dialog :title="$t('views_home_components_pledge_43')" :visible="visible" width="70%" @close="visible = false">
        <span>
            <el-form>
                <el-form-item :label="$t('views_home_components_pledge_44')" size="mini">
                    <el-input-number @change="amountChange" v-model.number="amount" size="mini" ref="amount" label="" @keypress="onlyNumbers"
                        :min="lastAmout" :step="100" :controls="true" controls-position="both" :placeholder="$t('views_community_components_promotion_levels_15')">
                    </el-input-number>
                </el-form-item>
            </el-form>
        </span>
        <span slot="footer">
            <el-button type="success" :loading="buttonLoading" :disabled="buttonDisabled"
                @click="deposit" size="small">{{$t('views_community_components_promotion_levels_16')}}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import i18n from '../../../locales/index'

import clock_wise from "@/config/clock_wise";
import { getClockWiseContract, getClockWiseStorageContract, getCoinContract } from "@/utils/contract";
import { getSignature } from '@/utils/http';
import infuraWeb3 from "@/utils/infura";
import web3 from "@/utils/myweb3";
import encodeMixedRoute from '@/utils/v4_router';
import { mapState } from "vuex";
import Web3 from "web3";
export default {
    props:{
        userInfo:{}
    },
    computed: {
        ...mapState(['userAdd']),
        buttonLoading() {
            return this.lastAmout == -1;
        },
        buttonDisabled() {
            return this.lastAmout == -1;
        },
    },
    watch: {
        visible() {
            if (this.visible) {
                this.lastAmout = -1;
                this.$nextTick(() => {
                    // this.$refs.amount.$el.children[2].children[0].setAttribute('readonly',true)
                })
                this.getLastPledgeAmount().then(amount => {
                    console.log('getLastPledgeAmount', amount)
                    if (amount == -1) {
                        this.visible = false;
                        return;
                    }
                    this.amount = amount;
                    this.lastAmout = amount;
                });
            }
        }
    },
    data() {
        return {
            visible: false,
            amount: 100,
            lastAmout: -1,
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {
        onlyNumbers(event) {
            // 允许输入小数点
            const isValid = (event.keyCode >= 48 && event.keyCode <= 57) ||
                // 允许删除和退格键
                event.keyCode === 8 || event.keyCode === 46 ||
                // 允许输入小数点
                //(event.keyCode === 190 || event.keyCode === 110) ||
                // 允许Ctrl+A 全选
                (event.ctrlKey && event.keyCode === 65) ||
                // 允许Ctrl+X 剪切
                (event.ctrlKey && event.keyCode === 88) ||
                // 允许Ctrl+V 粘贴
                (event.ctrlKey && event.keyCode === 86) ||
                // 允许Ctrl+Z 撤销
                (event.ctrlKey && event.keyCode === 90);

            if (!isValid) {
                event.preventDefault();
            }
        },
        amountChange(newVal, oldVal) {
            if(!(newVal+"").endsWith("00")){
                this.$nextTick(()=>{
                    this.$message.error(i18n.t('views_home_components_pledge_45'))
                    this.$forceUpdate();
                })
                return
            }
        },
        //获取上次贡献金额 下次贡献 只能比这个大 或者一样
        async getLastPledgeAmount() {
            try {
                let r = await getClockWiseStorageContract().methods.getUserPledge(this.userAdd).call({ from: this.userAdd });
                console.log(r)
                if (r.length == 0) return 100;
                return parseInt(web3.utils.fromWei(r[r.length - 1].amount, 'ether'));
            } catch (error) {
                console.log(error)
                this.visible = false;
                this.$message.error(i18n.t('views_home_components_pledge_46'))
                return -1;
            }
        },
        async deposit() {
            let loading;
            try {
                if (this.lastAmout == -1) {
                    return
                }
                if (this.amount < 0) {
                    this.$message.error(i18n.t('views_community_components_promotion_levels_17'))
                    return
                }
                if (this.lastAmout > this.amount) {
                    this.$message.error(i18n.t('views_home_components_pledge_47'))
                    return
                }
                if(!(this.amount+"").endsWith("00")){
                    this.$nextTick(()=>{
                        this.$message.error(i18n.t('views_home_components_pledge_45'))
                        this.amount = this.lastAmout;
                        this.$forceUpdate();
                    })
                    return
                }
                let coinContract = getCoinContract();
                let clockContract = getClockWiseContract();
                loading = this.$loading({ body: true })
                let balance = await coinContract.methods.balanceOf(this.userAdd).call({ from: this.userAdd })
                console.log('balance', balance)
                if (web3.utils.fromWei(balance, 'ether') < this.amount) {
                    loading.close()
                    this.$message.warning(i18n.t('views_home_components_pledge_48'));
                    return;
                }
                let allowance = await coinContract.methods.allowance(this.userAdd, clock_wise.address).call();
                console.log('allowance', Web3.utils.fromWei(allowance, 'ether'))
                if (allowance < Web3.utils.toWei(this.amount, 'ether')) {
                 //   this.$message.warning("授权额度不足，请重新授权");
                    await coinContract.methods.approve(clock_wise.address,
                        Web3.utils.toWei(this.amount, 'ether')).send({ from: this.userAdd })
                }
                console.log('pladge amount:', Web3.utils.toWei(this.amount, 'ether'))

                let percent = new Number(window.SwapTokenPairToken)/new Number(1000n);//0.2
                let percentAmount = new Number(this.amount)*percent;
                console.log("percentAmount",percentAmount)
                let routerPath = await encodeMixedRoute(Web3.utils.toWei(percentAmount, 'ether'));
                console.log("routerPath",routerPath)
                await this.$parent.checkUser();
                let {refer,referLevel,level} = this.userInfo;
                let signData = await getSignature(refer,referLevel,Web3.utils.toWei(this.amount, 'ether'),routerPath.path,routerPath.minOut,level,this.userAdd)
                let r = await clockContract.methods.pledge(Web3.utils.toWei(this.amount, 'ether'),routerPath.path,routerPath.minOut,signData.data.rewardList,signData.data.signData)
                    .send({ from: this.userAdd, gas: 10000000,});
                console.log('pladge result:', r)
                loading && loading.close();
                this.visible = false;
                if (r.status == '1') {
                    this.$message.success(i18n.t('views_home_components_pledge_49'));
                } else {
                    this.$message.error(i18n.t('views_home_components_pledge_50'));
                }
            } catch (e) {
                console.error(e)
                loading && loading.close();
                this.$message.error(i18n.t('views_home_components_pledge_50'));
            }
        },
    }
}
</script>