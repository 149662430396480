import i18n from '../locales/index'
import Web3, { FMT_BYTES, FMT_NUMBER } from "web3";

var web3 = new Web3(window.myprovider)
var timer=setInterval(() => {
    if(window.myprovider){
        web3 = new Web3(window.myprovider);
        clearInterval(timer);
    }
}, 10);
export default web3;