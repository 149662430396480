<template>
    <div class="box_6Menu flex-col" @touchmove="()=>{$emit('close')}" @click="()=>{$emit('close')}">
      <div class="men_group_4 flex-col" @click.stop="()=>{}">
        <div class="block_1 flex-row" @click="goto('/')">
          <img
            class="icon_4"
            referrerpolicy="no-referrer"
            src="@/assets/SketchPngac7cf8184628ccb95ec010b2d0b8c8eb416488895738d9cbfa873cb45e507126.png"
          />
          <span class="text_25">{{$t('components_menu_8')}}</span>
          <img
            class="icon_5"
            referrerpolicy="no-referrer"
            src="@/assets/SketchPng82a331178a3a152735d5e8fed417a07305516946e6ccf9dba703de9fcb5085ab.png"
          />
        </div>
        <div class="block_2 flex-row" @click="goto('/community')">
          <img
            class="icon_6"
            referrerpolicy="no-referrer"
            src="@/assets/SketchPnge186e1087e42ebc84982431225a29b3fa929a765144fec45de7137d22eb18df1.png"
          />
          <span class="text_26">{{$t('components_menu_9')}}</span>
          <img
            class="icon_7"
            referrerpolicy="no-referrer"
            src="@/assets/SketchPng82a331178a3a152735d5e8fed417a07305516946e6ccf9dba703de9fcb5085ab.png"
          />
        </div>
        <div class="block_3 flex-row" @click="goto('/myassets')">
          <img
            class="icon_8"
            referrerpolicy="no-referrer"
            src="@/assets/SketchPng927a0c5fd92bb5d06ce2f2de8994a9f3a99d17849d3e849265f2e5ce3383784d.png"
          />
          <span class="text_27">{{$t('components_menu_10')}}</span>
          <img
            class="icon_9"
            referrerpolicy="no-referrer"
            src="@/assets/SketchPng82a331178a3a152735d5e8fed417a07305516946e6ccf9dba703de9fcb5085ab.png"
          />
        </div>
        <div class="block_3 flex-row" @click="$message.info($t('components_menu_11'))">
          <img
            class="icon_8"
            referrerpolicy="no-referrer"
            src="@/assets/mingke.png"
          />
          <span class="text_27">{{$t('components_menu_12')}}</span>
          <img
            class="icon_9"
            referrerpolicy="no-referrer"
            src="@/assets/SketchPng82a331178a3a152735d5e8fed417a07305516946e6ccf9dba703de9fcb5085ab.png"
          />
        </div>
        <div class="block_3 flex-row" @click="$message.info($t('components_menu_11'))">
          <img
            class="icon_8"
            referrerpolicy="no-referrer"
            src="@/assets/kongtou.png"
          />
          <span class="text_27">{{$t('components_menu_13')}}</span>
          <img
            class="icon_9"
            referrerpolicy="no-referrer"
            src="@/assets/SketchPng82a331178a3a152735d5e8fed417a07305516946e6ccf9dba703de9fcb5085ab.png"
          />
        </div>
        <div class="image-wrapper_2 flex-row" v-if="false">
          <img
            class="label_5"
            referrerpolicy="no-referrer"
            src="@/assets/SketchPnga321e1ccd497d29e780454dccfe4ccdd2d73edd992042aaba7e84a3042ddc6c6.png"
          />
          <img
            class="label_6"
            referrerpolicy="no-referrer"
            src="@/assets/SketchPng084618db57cacc132af140714d4b0c3b369b83d8a211b98ddb9ca73715a5e784.png"
          />
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="@/assets/SketchPnge9016f76aba8209b9922210c1e15392fcdf42c37cfa2eaa7695ba269b91d7cdf.png"
          />
        </div>
        <div class="block_4 flex-row" v-if="false">
          <div class="text-wrapper_7">
            <span class="text_28">NOW&nbsp;PRICE&nbsp;：</span>
            <span class="text_29">0.365&nbsp;USD</span>
          </div>
        </div>
        <div class="block_5 flex-row">
          <div class="text-men_group_7 flex-col">
            <span class="text_30">CLOCKWISE&nbsp;&#64;2024~2030</span>
            <span class="text_31"
              >{{$t('base_on')}}</span
            >
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import i18n from '../locales/index'


export default {
  name: 'popmenu',
  methods:{
    goto(path){
      this.$router.push({path});
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.box_6Menu {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    padding-top: 4.694rem;
    .men_group_4 {
      height: 120vh;
      background: url(@/assets/SketchPng708393e9ac198a8d1c2c1dc83c038594fb0a0ff96375d8b3780c438e4dce25fb.png)
        100% no-repeat;
      background-size: 100% 100%;
      margin: 0 4.854rem -38.187rem 0;
      padding: 1.974rem 1.147rem 2.934rem 1.12rem;
      >div{
        justify-content: space-between;
        >span{
          flex:1;
        }
        >img:nth-child(3){
          margin-left: 0!important;
        }
      }
      .block_1 {
        .icon_4 {
          width: 1.067rem;
          height: 1.014rem;
          margin-top: 0.054rem;
        }
        .text_25 {
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 0.746rem;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: 1.067rem;
          margin-left: 0.64rem;
        }
        .icon_5 {
          width: 0.374rem;
          height: 0.64rem;
          margin: 0.214rem 0 0.214rem 0.307rem;
        }
      }
      .block_2 {
        margin: 1.6rem 0 0 0.054rem;
        .icon_6 {
          width: 1.014rem;
          height: 1.067rem;
        }
        .text_26 {
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 0.746rem;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: 1.067rem;
          margin-left: 0.64rem;
        }
        .icon_7 {
          width: 0.374rem;
          height: 0.64rem;
          margin: 0.214rem 0 0.214rem 7.814rem;
        }
      }
      .block_3 {
        margin: 1.6rem 0 0 0.054rem;
        .icon_8 {
          width: 1.014rem;
          height: 1.067rem;
        }
        .text_27 {
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 0.746rem;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: 1.067rem;
          margin-left: 0.64rem;
        }
        .icon_9 {
          width: 0.374rem;
          height: 0.64rem;
          margin: 0.214rem 0 0.214rem 7.814rem;
        }
      }
      .image-wrapper_2 {
        width: 4.72rem;
        align-self: center;
        margin-top: 2.374rem;
        .label_5 {
          width: 1.12rem;
          height: 1.12rem;
          margin: 0.107rem 0 0.134rem 0;
        }
        .label_6 {
          width: 1.12rem;
          height: 1.12rem;
          margin: 0.107rem 0 0.134rem 0.774rem;
        }
        .image_2 {
          width: 1.36rem;
          height: 1.36rem;
          margin-left: 0.347rem;
        }
      }
      .block_4 {
        margin: 0.587rem 1.387rem 0 1.574rem;
        .text-wrapper_7 {
          width: 9.92rem;
          height: 1.28rem;
          overflow-wrap: break-word;
          font-size: 0;
          font-family: AlibabaPuHuiTi_2_55_Regular;
          font-weight: normal;
          text-align: right;
          white-space: nowrap;
          line-height: 1.28rem;
          .text_28 {
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 0.853rem;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 1.28rem;
          }
          .text_29 {
            overflow-wrap: break-word;
            color: rgba(248, 188, 66, 1);
            font-size: 0.853rem;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 1.28rem;
          }
        }
      }
      .block_5 {
        margin: 20.347rem 1.467rem 0 2.134rem;
        .text-men_group_7 {
          align-items: center;
          .text_30 {
            height: 0.747rem;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 0.746rem;
            font-family: PangMenZhengDao;
            font-weight: normal;
            text-align: center;
            white-space: nowrap;
            line-height: 0.854rem;
          }
          .text_31 {
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 0.64rem;
            font-weight: normal;
            text-align: right;
            white-space: nowrap;
            line-height: 0.88rem;
            margin-top: 0.214rem;
          }
        }
      }
    }
  }
</style>